<template>
  <div id="change-pin" class="tw-relative">
    <div class="content tw-py-10">
      <h3 class="tw-text-2xl tw-font-CircularStd-medium tw-font-medium tw-mb-2">
        Change PIN
      </h3>
      <p class="tw-text-gray-bg1 tw-text-sm tw-mb-12">
        Secure your account
      </p>
      <p
        class="tw-absolute tw-top-12 tw-right-0 tw-hidden lg:tw-block tw-text-black-light tw-text-sm hover:tw-text-primary tw-cursor-pointer"
        @click="$emit('back')"
      >
        Back to Security
      </p>
      <div
        class="tw-absolute tw-top-12 sm:tw-top-12 tw-right-0 lg:tw-hidden tw-py-0.5 tw-px-2 tw-border tw-border-black-light tw-rounded"
        @click="$emit('back')"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.02698 11.9929L5.26242 16.2426L6.67902 14.8308L4.85766 13.0033L22.9731 13.0012L22.9728 11.0012L4.85309 11.0033L6.6886 9.17398L5.27677 7.75739L1.02698 11.9929Z"
            fill="#4F5877"
          />
        </svg>
      </div>
      <form @submit.prevent="confirmPassword">
        <SecurityPinInput
          class="tw-mb-6"
          placeHolder="Enter new PIN"
          label="new pin"
          :pin="payload.newPin"
          inputName="newPin"
          :disabled="loading"
          @set="setNewPin"
        />
        <SecurityPinInput
          class="tw-mb-6"
          placeHolder="Confirm new PIN"
          label="confirm pin"
          :pin="payload.confirmNewPin"
          :error="confirmPinError || {}"
          inputName="confirmPin"
          :disabled="enableConfirmNewPin"
          @set="setConfirmNewPin"
          @validate="validateMethod"
        />
        <p class="tw-text-sm tw-mb-2">Enter password to confirm</p>
        <OnboardingPasswordInput
          label=""
          :password="payload.password"
          :error="passwordError || {}"
          name="password"
          :disabled="loading"
          @set="setPassword"
        />
        <button
          class="tw-w-full tw-rounded tw-text-sm tw-bg-primary tw-text-white md:tw-w-48 tw-p-4 tw-mt-8"
        >
          <span v-show="!loading">Change PIN</span>
          <img
            v-show="loading"
            class="tw-m-auto"
            src="@/assets/img/spinner.svg"
            alt="animated spinner"
          />
        </button>
      </form>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import useValidate from "@vuelidate/core";
  import { required, sameAs } from "@vuelidate/validators";
  import OnboardingPasswordInput from "@/components/onboarding/OnboardingPasswordInput";
  import SecurityPinInput from "@/components/account/SecurityPinInput";

  export default {
    name: "ChangePin",

    components: { OnboardingPasswordInput, SecurityPinInput },

    emits: ["back"],

    data() {
      return {
        v$: useValidate(),
        loading: false,
        payload: {
          pin: "",
          confirmNewPin: "",
          password: "",
        },
        enableConfirmNewPin: true,
        confirmPinError: {},
        passwordError: {},
      };
    },
    computed: {
      ...mapGetters("organisationModule", ["organisationDetails"]),
    },

    validations() {
      return {
        payload: {
          confirmNewPin: {
            required,
            sameAs: sameAs(this.payload.pin),
          },
        },
      };
    },

    methods: {
      ...mapActions(["showToast"]),
      ...mapActions("Account", ["resetPin", "verifyPassword"]),
      async confirmPassword() {
        this.v$.$validate();
        const hasValue = Object.keys(this.payload).every(
          (key) => this.payload[key]
        );
        if (this.v$.$errors.length === 0 && hasValue) {
          this.loading = true;
        }
        const payload = {
          email: this.organisationDetails.email,
          password: this.payload.password,
        };
        try {
          await this.verifyPassword(payload);
          this.submitData();
        } catch (error) {
          this.loading = false;
        }
      },

      async submitData() {
        delete this.payload.confirmNewPin;
        try {
          const response = await this.resetPin(this.payload);
          const { message } = response.data;
          this.showToast({
            description: message,
            display: true,
            type: "success",
          });
          this.$emit("back");
          this.loading = false;
        } catch (error) {
          this.loading = false;
        }
      },

      setNewPin(pin) {
        this.payload.pin = pin;
        this.payload.confirmNewPin = "";
        if (pin.length === 4) {
          this.enableConfirmNewPin = false;
        } else {
          this.enableConfirmNewPin = true;
        }
      },

      setConfirmNewPin(pin) {
        this.payload.confirmNewPin = pin;
      },

      setPassword(password) {
        this.payload.password = password;
      },

      validateMethod(field) {
        switch (field) {
          case "Confirm new PIN":
            this.v$.$reset();
            this.v$.payload.confirmNewPin.$touch();
            if (this.v$.$errors.length === 0)
              return (this.confirmPinError = {});
            this.confirmPinError = this.v$.$errors[0];
            break;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  #change-pin {
    @include fadeIn;

    .content {
      @media screen and (min-width: 768px) {
        max-width: 460px;
      }
    }
  }
</style>
