<template>
  <div id="personal-info">
    <form @submit.prevent="submitAccountType" class=" form tw-py-10">
      <div class="tw-mb-20">
        <h1
          class="tw-text-2xl tw-font-CircularStd-medium tw-font-medium tw-mb-10"
        >
          {{ orgDetails.founders.length > 1 ? "Founders" : "Founders" }}
          Information
        </h1>
        <!-- <p class="tw-text-gray-bg1 tw-text-sm ">
          Edit your partner information
        </p> -->
        <div
          class="tw-mb-6"
          v-for="partner in orgDetails.founders"
          :key="partner._id"
        >
          <div
            class="md:tw-flex tw-flex-wrap md:tw-justify-between tw-gap-x-4 tw-mb-6"
          >
            <div class="tw-w-full md:tw-mb-0 md:tw-w-[48%]">
              <label for="first name" class="tw-text-sm">First name</label>
              <input
                type="text"
                class="tw-w-full tw-text-sm tw-rounded tw-p-4 tw-mt-2 tw-mb-6 md:tw-mb-0 tw-border-gray-darkest"
                v-model="partner.fname"
                disabled
              />
            </div>
            <div class="tw-w-full md:tw-w-[48%]">
              <label for="last name" class="tw-text-sm">Last name</label>
              <input
                type="text"
                class="tw-w-full tw-text-sm tw-rounded tw-p-4 tw-mt-2 tw-mb-2 md:tw-mb-0 tw-border-gray-darkest"
                v-model="partner.lname"
                disabled
              />
            </div>
            <!-- <p class="tw-w-full md:tw-mt-2 tw-text-xs tw-text-gray">
              This name must correspond to the name on your ID
            </p> -->
          </div>
          <label for="email" class="tw-text-sm tw-text-gray-black"
            >Email address</label
          >
          <input
            type="email"
            class="tw-w-full tw-text-sm tw-rounded tw-mt-2 tw-p-4 tw-border-gray-darkest"
            v-model="partner.email"
            disabled
          />
          <!-- <p class="tw-w-full md:tw-mt-2 tw-text-xs tw-text-gray">
            This is your personal email for contacting you.
          </p> -->
        </div>
      </div>

      <div class="tw-mb-10">
        <h3
          class="tw-text-lg tw-mb-1 tw-text-black tw-font-CircularStd-medium tw-font-medium"
        >
          Syndicate Information
        </h3>
        <p class="tw-text-gray-bg1 tw-text-sm tw-mb-10">
          Edit your Syndicate information
        </p>
        <div class="">
          <label for="syndicate name" class="tw-text-sm tw-text-black">
            Syndicate name
          </label>
          <input
            type="text"
            name="syndicate name"
            class="tw-w-full tw-text-sm tw-rounded tw-p-4 tw-mt-2 tw-mb-8 focus:tw-border-primary tw-border-gray-darkest"
            v-model="syndicateName"
            @blur="checkField"
          />
          <label for="syndicate email" class="tw-text-sm tw-text-black">
            Syndicate email
          </label>
          <input
            type="email"
            name="syndicate email"
            class="tw-w-full tw-text-sm tw-rounded tw-p-4 tw-mt-2 tw-mb-8 focus:tw-border-primary tw-border-gray-darkest"
            v-model="syndicateEmail"
            @blur="checkField"
          />
          <label for="syndicate email" class="tw-text-sm tw-text-black">
            About your Syndicate
          </label>
          <textarea
            name="about"
            id="about"
            v-model="about"
            @blur="checkField"
            class="tw-w-full tw-text-sm tw-rounded tw-p-4 tw-mt-2 tw-mb-8 tw-h-32 focus:tw-border-primary tw-border-gray-darkest"
          ></textarea>
          <label for="syndicate address" class="tw-text-sm tw-text-black"
            >Address</label
          >
          <input
            type="text"
            name="syndicate address"
            class="tw-w-full tw-text-sm tw-rounded tw-mt-2 tw-p-4 focus:tw-border-primary tw-border-gray-darkest"
            v-model="address"
            @blur="checkField"
          />
        </div>
      </div>
      <button
        class="tw-w-full tw-rounded tw-text-sm tw-bg-primary tw-text-white md:tw-w-48 tw-p-4"
        :disabled="loading"
      >
        <span v-show="!loading">Save changes</span>
        <img
          v-show="loading"
          class="tw-m-auto"
          src="@/assets/img/spinner.svg"
          alt="animated spinner"
        />
      </button>
    </form>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import { blankString } from "@/utils/helpers.js";

  export default {
    name: "PersonalInfo",

    props: {
      orgDetails: { type: Object, default: () => {}, required: true },
    },

    data() {
      return {
        loading: false,
        firstName: this.orgDetails.founders[0]?.fname,
        lastName: this.orgDetails.founders[0]?.lname,
        email: this.orgDetails.founders[0]?.email,
        about: this.orgDetails.about,
        syndicateName: this.orgDetails?.name,
        syndicateEmail: this.orgDetails?.email,
        address: this.orgDetails?.officeAddress,
      };
    },

    methods: {
      ...mapActions("organisationModule", ["updateOrgDetails"]),
      ...mapActions(["showToast"]),

      async submitAccountType() {
        if (blankString(this.address)) {
          this.showToast({
            description: "Syndicate address can not be blank",
            display: true,
            type: "error",
          });
          return;
        }
        const payload = {
          officeAddress: this.address,
          name: this.syndicateName,
          about: this.about,
        };

        try {
          this.loading = true;
          const response = await this.updateOrgDetails(payload);
          const { message } = response.data;
          this.showToast({
            description: message,
            display: true,
            type: "success",
          });
          this.loading = false;
        } catch (error) {
          this.loading = false;
        }
      },

      checkField(event) {
        if (blankString(event.target.value)) {
          switch (event.target.name) {
            case "syndicate name":
              this.syndicateName = this.orgDetails?.name;
              break;
            case "syndicate email":
              this.syndicateEmail = this.orgDetails?.email;
              break;
            case "syndicate address":
              this.address = this.orgDetails?.officeAddress;
              break;
            case "about":
              this.about = this.orgDetails?.about;
              break;
          }
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  #personal-info {
    @include fadeIn;

    .form {
      @media screen and (min-width: 768px) {
        max-width: 460px;
      }
    }
  }
</style>
