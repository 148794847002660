<template>
  <div class="nav md:tw-border-r tw-rounded-lg tw-bg-white">
    <div
      class="header tw-flex tw-justify-between tw-items-center tw-px-4 tw-py-6"
    >
      <div class="tw-flex tw-items-center tw-gap-x-2">
        <img
          class="tw-h-16 tw-w-16 tw-rounded-full"
          :src="
            organisationDetails.image ||
              'https://res.cloudinary.com/djalafcj9/image/upload/v1634012152/getequityV2/header-profile-img_kjtkcq.webp'
          "
          alt="profile"
          loading="lazy"
        />
        <div class="">
          <h3
            class="tw-text-secondary tw-mb-1 tw-font-CircularStd-medium tw-font-medium"
          >
            {{ truncate(organisationDetails.name, 20) }}
          </h3>
          <p v-if="orgCountry" class="tw-text-[#4C5B90]">
            {{ truncate(orgCountry, 20) }}
          </p>
          <p v-else class="tw-text-[#4C5B90]">-</p>
        </div>
      </div>
      <img
        src="@/assets/img/options-action.svg"
        class="md:tw-hidden tw-w-8 tw-h-8 tw-cursor-pointer"
        alt="share"
        loading="lazy"
        @click="showAccountSidebar = !showAccountSidebar"
      />
    </div>
    <div class="account-sidebar">
      <AccountSidebar
        v-if="showAccountSidebar"
        class="md:tw-hidden"
        :focus="layout"
        @closeMenu="showAccountSidebar = false"
        @switchLayout="switchLayout"
        @show-live-chat="showLiveChat"
      />
      <AccountSidebar
        class="tw-hidden md:tw-block"
        :focus="layout"
        @switchLayout="switchLayout"
        @show-live-chat="showLiveChat"
      />
    </div>
  </div>
</template>

<script>
  var Tawk_API = Tawk_API || {},
    Tawk_LoadStart = new Date();
  import AccountSidebar from "@/layout/navigation/AccountSidebar";
  import { mapGetters } from "vuex";
  import { truncate } from "@/utils/helpers.js";
  import countryRegionState from "@/utils/js/countryRegionData";
  export default {
    name: "AccountSideNav",
    data() {
      return {
        showAccountSidebar: false,
        layout: "Personal Information",
        countryRegionState,
      };
    },
    components: {
      AccountSidebar,
    },

    computed: {
      ...mapGetters("organisationModule", ["organisationDetails"]),
      orgCountry() {
        let country = null;
        let countryInfo = this.countryRegionState.filter(
          (country) =>
            country.countryShortCode === this.organisationDetails.country ||
            country.countryName === this.organisationDetails.country
        );
        country = countryInfo.length > 0 && countryInfo[0].countryName;
        return country;
      },
    },
    methods: {
      truncate,
      showLiveChat() {
        (function() {
          var s1 = document.createElement("script"),
            s0 = document.getElementsByTagName("script")[0];
          s1.async = true;
          s1.src = "https://embed.tawk.to/5f9e9dac16ea1756a6df3db4/default";
          s1.charset = "UTF-8";
          s1.setAttribute("crossorigin", "*");
          s0.parentNode.insertBefore(s1, s0);
        })();
      },

      switchLayout(layout) {
        switch (layout) {
          case "Personal Information":
            this.layout = "Personal Information";
            break;
          case "KYC Documents":
            this.layout = "KYC Documents";
            break;
          case "Bank Information":
            this.layout = "Bank Information";
            break;
          case "Security":
            this.layout = "Security";
            break;
          case "Subscription Plans":
            this.layout = "Subscription Plans";
            break;
          case "Configurations":
            this.layout = "Configurations";
            break;

          default:
            break;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .nav {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12);
    @media screen and (min-width: 1024px) {
      width: 336px;
      padding: 40px 45px;
    }
  }
</style>
