<template>
  <SideModal
    title="Subscription Plan"
    :showBackButton="false"
    @close="$emit('close-modal')"
  >
    <template v-slot:content>
      <form
        @submit.prevent="getActionType"
        class="tw-w-full tw-max-w-[375px] tw-mx-auto"
      >
        <div class="tw-rounded tw-w-full tw-bg-gray-bg3 tw-py-4 tw-mb-2">
          <p class="tw-text-center tw-text-[0.938rem] tw-text-[#323E67]">
            Selected Plan:
          </p>
          <p
            class="tw-text-secondary tw-font-bold tw-text-lg tw-capitalize tw-text-center"
          >
            {{ plan.type }} {{ plan.duration }}
          </p>
        </div>
        <div
          v-if="plan.type.toLowerCase() === 'fixed'"
          class="tw-bg-gray-lightest tw-rounded tw-pt-6 tw-text-center tw-pb-4 tw-border tw-border-blue-bg200 tw-mb-4 tw-px-4"
        >
          <p class="tw-font-bold tw-text-secondary tw-text-lg tw-mb-5">
            Please note you will be charged {{ plan.percentage }}% on all deals
            raised in your portal, you can choose to share these fees with
            potential investors as their transaction fees.
          </p>
        </div>
        <div
          v-else
          class="tw-bg-gray-lightest tw-rounded tw-pt-6 tw-text-center tw-pb-4 tw-border tw-border-blue-bg200 tw-mb-4"
        >
          <p class="tw-text-[#323E67] tw-text-[0.938rem]">
            You are subscribing for;
          </p>
          <p class="tw-font-bold tw-text-secondary tw-text-lg tw-mb-5">
            {{ totalMembers }} {{ totalMembers > 1 ? "members" : "member" }}
          </p>
          <template v-if="plan.type.toLowerCase() !== 'fixed'">
            <hr />
            <p class="tw-text-[#323E67] tw-mt-2 tw-text-sm">
              at {{ formatAmountToDollar(plan.amount, 2, "USD") }} per member
              per
              {{ getPlanDuration(plan.duration) }}
            </p>
          </template>
        </div>

        <CustomButton
          v-if="plan.type.toLowerCase() === 'fixed'"
          class="tw-mt-6"
          title="Initiate Plan"
          :loading="loading"
        />
        <template v-else>
          <p class="tw-text-'[#323E67]' tw-text-lg tw-mb-1 tw-text-center">
            You pay
          </p>
          <p
            class="tw-text-secondary tw-font-CircularStd-medium tw-mt-1 tw-text-[1.75rem] tw-tracking-[-0.1px] tw-text-center tw-mb-4"
          >
            {{ formatAmountToDollar(totalCost, 2, "USD") }}
          </p>
          <p class="tw-text-[#323E67] tw-text-lg tw-mb-1 tw-text-center">
            Your available balance is
            <span class="tw-font-bold tw-text-secondary">
              {{ formatAmountToDollar(tradeBalance, 2, "USD") }}
            </span>
          </p>
          <template v-if="totalCost > tradeBalance">
            <div
              class="tw-rounded tw-bg-[#FDF2E7] tw-px-6 tw-py-3 tw-mt-6 tw-flex tw-gap-4"
            >
              <img
                class="tw-w-8 tw-h-8"
                src="https://res.cloudinary.com/djalafcj9/image/upload/v1661781045/getequityV2/info-orange_o9aegn.svg"
                alt="info icon"
              />
              <div>
                <p class="tw-text-[#694521] tw-text-sm tw-tracking-[-0.2px]">
                  You do not have sufficient cash for this subscription. Kindly
                  <span class="tw-font-CircularStd-medium"
                    >fund your wallet</span
                  >
                  to subscribe
                </p>
              </div>
            </div>

            <CustomButton
              buttonType="button"
              @click="showFundModal = true"
              class="tw-mt-6"
              title="Fund Wallet"
              :loading="loading"
            />
          </template>
          <CustomButton
            v-else
            class="tw-mt-6"
            title="Activate Plan"
            :loading="loading"
          />
        </template>
      </form>
      <FundModal v-if="showFundModal" @close-modal="showFundModal = false" />
    </template>
  </SideModal>
</template>

<script setup>
  import SideModal from "@/components/general/sidemodal/SideModal.vue";
  import CustomButton from "@/components/general/BtnComponent.vue";
  import FundModal from "@/components/wallet/fund/FundModal.vue";
  import { capitalizeEachWord, formatAmountToDollar } from "@/utils/helpers.js";
  import { computed, ref, watch } from "vue";
  import { useStore } from "vuex";
  import { setItem } from "@/utils/storage";

  const props = defineProps({
    plan: {
      type: Object,
      required: true,
    },
  });
  const showFundModal = ref(false);

  const plan = computed(() => props.plan);
  const emit = defineEmits(["close-modal"]);

  const store = useStore();

  const loading = computed(() => store.getters["loading"]);

  const totalMembers = computed(
    () => store.getters["organisationModule/totalMembers"]
  );

  const totalCost = computed(() => totalMembers.value * plan.value.amount);

  const tradeBalance = computed(
    () => store.getters["organisationModule/tradeBalance"]
  );

  const getPlanDuration = (duration) => {
    let period = null;
    switch (duration) {
      case "Monthly":
        period = "month";
        break;
      case "Annually":
        period = "annum";
        break;
    }
    return period;
  };
  const getActionType = () => {
    if (plan.value.type === "fixed") {
      initiatePlan();
    } else {
      payForPlan();
    }
  };

  const initiatePlan = async () => {
    const payload = plan.value;
    payload.type = capitalizeEachWord(payload.type);
    await store.dispatch("organisationModule/initiatePlan", payload);
    await store.dispatch("organisationModule/getOrganisationPlan");
    emit("close-modal");
  };

  const payForPlan = async () => {
    const payload = { members: totalMembers.value, amount: totalCost.value };
    const response = await store.dispatch(
      "organisationModule/payForPlan",
      payload
    );
    const { message } = response.data;
    store.dispatch("showToast", {
      description: message,
      display: true,
      type: "success",
    });
    setItem("ge-subscription", null);
    emit("close-modal");
  };

  watch(showFundModal, (value) => {
    if (value) {
      setItem("ge-subscription", plan.value);
    }
  });
</script>

<style lang="scss" scoped></style>
