<template>
  <div
    class="account tw-h-screen tw-overflow-y-scroll tw-pb-6 tw-px-4 lg:tw-px-8 main-content"
  >
    <div class="container tw-px-4 md:tw-px-0">
      <div class="tw-relative md:tw-flex tw-justify-center">
        <AccountSideNav />
        <div class="right-side">
          <router-view v-slot="{ Component }">
            <transition name="fade-transform" mode="out-in">
              <component
                :is="Component"
                class="tw-pb-6 tw-px-4 lg:tw-px-8 main-content"
              />
            </transition>
          </router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from "vuex";
  import AccountSideNav from "@/components/account/AccountSideNav";
  import AccountSidebar from "@/layout/navigation/AccountSidebar";
  import PersonalInfo from "@/components/account/PersonalInfo";
  import KycInfo from "@/components/account/KycInfo";
  import BankInfo from "@/components/account/BankInfo";
  import { truncate } from "@/utils/helpers.js";

  export default {
    name: "Account",

    components: {
      AccountSideNav,
      AccountSidebar,
      PersonalInfo,
      KycInfo,
      BankInfo,
    },

    data() {
      return {
        showAccountSidebar: false,
        layout: "Personal Information",
      };
    },

    computed: {
      ...mapState({
        orgDetails: (state) => state.organisationModule.organisationDetails,
      }),
    },

    created() {
      this.getOrgInfo();
    },

    methods: {
      ...mapActions("organisationModule", ["getOrganisationProfile"]),
      ...mapActions(["showToast"]),

      truncate,

      async getOrgInfo() {
        try {
          await this.getOrganisationProfile();
        } catch (error) {
          return error;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .account {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    @media screen and (min-width: 768px) {
      height: calc(100vh - 140px);
    }

    @media screen and (min-width: 1280px) {
      height: calc(100vh - 120px);
    }
  }
  .container {
    @include fadeIn;
    @media screen and (min-width: 1280px) {
      height: calc(100vh - 120px);
    }
    .right-side {
      background-color: #fff;
      @media screen and (min-width: 768px) {
        width: calc(100% - 18rem);
      }
      @media screen and (min-width: 1024px) {
        width: calc(100% - calc(#{$account-side-bar-width} - 20px));
        max-width: 754px;
      }

      .right-side {
        @media screen and (min-width: 768px) {
          width: calc(100% - #{$account-side-bar-width});
          margin-left: $account-side-bar-width;
        }

        .content {
          @media screen and (min-width: 820px) {
            padding-left: 4%;
            padding-right: 4%;
          }
        }
      }
    }
  }
</style>
