<template>
  <router-link
    :to="{ name: routeName }"
    class="tw-flex tw-justify-between tw-items-center tw-text-gray hover:tw-text-primary tw-cursor-pointer tw-py-5"
    :class="{
      'tw-text-primary': $route.name === routeName,
    }"
    @click="clicked"
  >
    <span class="tw-flex tw-items-center">
      <svg
        v-if="title === 'Personal Information'"
        width="12"
        height="15"
        viewBox="0 0 12 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.3333 14.6666H0.666626V13.3333C0.666626 12.4492 1.01782 11.6014 1.64294 10.9763C2.26806 10.3511 3.1159 9.99996 3.99996 9.99996H7.99996C8.88401 9.99996 9.73186 10.3511 10.357 10.9763C10.9821 11.6014 11.3333 12.4492 11.3333 13.3333V14.6666ZM5.99996 8.66663C5.47467 8.66663 4.95453 8.56316 4.46923 8.36214C3.98392 8.16113 3.54297 7.86649 3.17153 7.49505C2.8001 7.12362 2.50546 6.68266 2.30444 6.19736C2.10342 5.71206 1.99996 5.19191 1.99996 4.66663C1.99996 4.14134 2.10342 3.62119 2.30444 3.13589C2.50546 2.65059 2.8001 2.20963 3.17153 1.8382C3.54297 1.46676 3.98392 1.17213 4.46923 0.971108C4.95453 0.770089 5.47467 0.666626 5.99996 0.666626C7.06082 0.666626 8.07824 1.08805 8.82839 1.8382C9.57853 2.58834 9.99996 3.60576 9.99996 4.66663C9.99996 5.72749 9.57853 6.74491 8.82839 7.49505C8.07824 8.2452 7.06082 8.66663 5.99996 8.66663Z"
          :fill="$route.name === routeName ? '#2A62F5' : '#8791A7'"
        />
      </svg>
      <svg
        v-if="title === 'KYC Documents'"
        width="12"
        height="14"
        viewBox="0 0 12 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.3333 13.6667H0.666667C0.489856 13.6667 0.320286 13.5965 0.195262 13.4714C0.0702379 13.3464 0 13.1769 0 13V1.00004C0 0.82323 0.0702379 0.65366 0.195262 0.528636C0.320286 0.403612 0.489856 0.333374 0.666667 0.333374H11.3333C11.5101 0.333374 11.6797 0.403612 11.8047 0.528636C11.9298 0.65366 12 0.82323 12 1.00004V13C12 13.1769 11.9298 13.3464 11.8047 13.4714C11.6797 13.5965 11.5101 13.6667 11.3333 13.6667ZM3.33333 3.66671V5.00004H8.66667V3.66671H3.33333ZM3.33333 6.33337V7.66671H8.66667V6.33337H3.33333ZM3.33333 9.00004V10.3334H6.66667V9.00004H3.33333Z"
          :fill="$route.name === routeName ? '#2A62F5' : '#8791A7'"
        />
      </svg>
      <svg
        v-if="title === 'Live Chat'"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 18H18.237L20 19.385V9H21C21.2652 9 21.5196 9.10536 21.7071 9.29289C21.8946 9.48043 22 9.73478 22 10V23.5L17.545 20H9C8.73478 20 8.48043 19.8946 8.29289 19.7071C8.10536 19.5196 8 19.2652 8 19V18ZM5.455 16L1 19.5V4C1 3.73478 1.10536 3.48043 1.29289 3.29289C1.48043 3.10536 1.73478 3 2 3H17C17.2652 3 17.5196 3.10536 17.7071 3.29289C17.8946 3.48043 18 3.73478 18 4V16H5.455Z"
          :fill="$route.name === routeName ? '#2A62F5' : '#8791A7'"
        />
      </svg>

      <svg
        v-if="title === 'Bank Information'"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.333252 12.3334H13.6666V13.6667H0.333252V12.3334ZM1.66659 7.00004H2.99992V11.6667H1.66659V7.00004ZM4.99992 7.00004H6.33325V11.6667H4.99992V7.00004ZM7.66659 7.00004H8.99992V11.6667H7.66659V7.00004ZM10.9999 7.00004H12.3333V11.6667H10.9999V7.00004ZM0.333252 3.66671L6.99992 0.333374L13.6666 3.66671V6.33337H0.333252V3.66671ZM6.99992 4.33337C7.17673 4.33337 7.3463 4.26314 7.47132 4.13811C7.59635 4.01309 7.66659 3.84352 7.66659 3.66671C7.66659 3.4899 7.59635 3.32033 7.47132 3.1953C7.3463 3.07028 7.17673 3.00004 6.99992 3.00004C6.82311 3.00004 6.65354 3.07028 6.52851 3.1953C6.40349 3.32033 6.33325 3.4899 6.33325 3.66671C6.33325 3.84352 6.40349 4.01309 6.52851 4.13811C6.65354 4.26314 6.82311 4.33337 6.99992 4.33337Z"
          :fill="$route.name === routeName ? '#2A62F5' : '#8791A7'"
        />
      </svg>
      <svg
        v-if="title === 'Security'"
        width="12"
        height="15"
        viewBox="0 0 12 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 5.33329H11.3333C11.5101 5.33329 11.6797 5.40353 11.8047 5.52855C11.9298 5.65358 12 5.82315 12 5.99996V14C12 14.1768 11.9298 14.3463 11.8047 14.4714C11.6797 14.5964 11.5101 14.6666 11.3333 14.6666H0.666667C0.489856 14.6666 0.320286 14.5964 0.195262 14.4714C0.0702379 14.3463 0 14.1768 0 14V5.99996C0 5.82315 0.0702379 5.65358 0.195262 5.52855C0.320286 5.40353 0.489856 5.33329 0.666667 5.33329H2V4.66663C2 3.60576 2.42143 2.58834 3.17157 1.8382C3.92172 1.08805 4.93913 0.666626 6 0.666626C7.06087 0.666626 8.07828 1.08805 8.82843 1.8382C9.57857 2.58834 10 3.60576 10 4.66663V5.33329ZM5.33333 10.488V12H6.66667V10.488C6.92086 10.3412 7.11952 10.1147 7.23184 9.8435C7.34416 9.57233 7.36387 9.27168 7.2879 8.98817C7.21193 8.70466 7.04454 8.45413 6.81168 8.27545C6.57882 8.09678 6.29351 7.99993 6 7.99993C5.70649 7.99993 5.42118 8.09678 5.18832 8.27545C4.95546 8.45413 4.78807 8.70466 4.7121 8.98817C4.63613 9.27168 4.65584 9.57233 4.76816 9.8435C4.88048 10.1147 5.07914 10.3412 5.33333 10.488ZM8.66667 5.33329V4.66663C8.66667 3.95938 8.38572 3.2811 7.88562 2.78101C7.38552 2.28091 6.70724 1.99996 6 1.99996C5.29276 1.99996 4.61448 2.28091 4.11438 2.78101C3.61428 3.2811 3.33333 3.95938 3.33333 4.66663V5.33329H8.66667Z"
          :fill="$route.name === routeName ? '#2A62F5' : '#8791A7'"
        />
      </svg>
      <svg
        v-if="title === 'Subscription Plans'"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.99992 13.6667C3.31792 13.6667 0.333252 10.682 0.333252 7.00004C0.333252 3.31804 3.31792 0.333374 6.99992 0.333374C10.6819 0.333374 13.6666 3.31804 13.6666 7.00004C13.6666 10.682 10.6819 13.6667 6.99992 13.6667ZM7.66659 7.00004V3.66671H6.33325V8.33337H10.3333V7.00004H7.66659Z"
          :fill="$route.name === routeName ? '#2A62F5' : '#8791A7'"
        />
      </svg>
      <svg
        v-if="title === 'Configurations'"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.11325 11C3.25099 10.6096 3.50641 10.2716 3.84432 10.0325C4.18223 9.7934 4.58598 9.665 4.99992 9.665C5.41386 9.665 5.81761 9.7934 6.15552 10.0325C6.49343 10.2716 6.74885 10.6096 6.88659 11H13.6666V12.3333H6.88659C6.74885 12.7237 6.49343 13.0617 6.15552 13.3008C5.81761 13.5399 5.41386 13.6683 4.99992 13.6683C4.58598 13.6683 4.18223 13.5399 3.84432 13.3008C3.50641 13.0617 3.25099 12.7237 3.11325 12.3333H0.333252V11H3.11325ZM7.11325 6.3333C7.25099 5.94294 7.50641 5.60492 7.84432 5.36582C8.18223 5.12673 8.58598 4.99833 8.99992 4.99833C9.41386 4.99833 9.81761 5.12673 10.1555 5.36582C10.4934 5.60492 10.7489 5.94294 10.8866 6.3333H13.6666V7.66663H10.8866C10.7489 8.05699 10.4934 8.39501 10.1555 8.63411C9.81761 8.8732 9.41386 9.0016 8.99992 9.0016C8.58598 9.0016 8.18223 8.8732 7.84432 8.63411C7.50641 8.39501 7.25099 8.05699 7.11325 7.66663H0.333252V6.3333H7.11325ZM3.11325 1.66663C3.25099 1.27628 3.50641 0.938255 3.84432 0.699158C4.18223 0.460062 4.58598 0.331665 4.99992 0.331665C5.41386 0.331665 5.81761 0.460062 6.15552 0.699158C6.49343 0.938255 6.74885 1.27628 6.88659 1.66663H13.6666V2.99997H6.88659C6.74885 3.39032 6.49343 3.72834 6.15552 3.96744C5.81761 4.20654 5.41386 4.33493 4.99992 4.33493C4.58598 4.33493 4.18223 4.20654 3.84432 3.96744C3.50641 3.72834 3.25099 3.39032 3.11325 2.99997H0.333252V1.66663H3.11325Z"
          :fill="$route.name === routeName ? '#2A62F5' : '#8791A7'"
        />
      </svg>
      <span
        class="tw-pl-5 tw-font-CircularStd-medium tw-font-medium"
        :class="[$route.name === routeName ? 'tw-text-primary' : '#8791A7']"
        >{{ title }}</span
      >
    </span>
    <svg
      class="tw-hidden md:tw-block"
      width="8"
      height="13"
      viewBox="0 0 8 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.95 6.364L0 1.414L1.414 0L7.778 6.364L1.414 12.728L0 11.314L4.95 6.364Z"
        :fill="$route.name === routeName ? '#2A62F5' : '#8791A7'"
      />
    </svg>
  </router-link>
</template>

<script>
  export default {
    name: "AccountMenu",
    props: {
      title: {
        type: String,
        required: true,
        default: () => "",
      },
      routeName: {
        type: String,
        required: false,
      },
      focus: { type: Boolean, default: () => false },
    },
    emits: ["clicked"],
    methods: {
      clicked() {
        this.$emit("clicked", this.title);
      },
    },
  };
</script>

<style lang="scss" scoped></style>
