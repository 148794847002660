<template>
  <div id="account-sidebar" class="tw-relative md:tw-static">
    <nav
      class="sidebar tw-absolute tw-bg-white md:tw-static md:tw-h-screen md:tw-pt-8 tw-w-full tw-px-4 tw-z-10"
    >
      <AccountMenu
        title="Personal Information"
        routeName="ProfileInfo"
        @clicked="switchContent"
      />
      <AccountMenu
        title="KYC Documents"
        routeName="Kyc"
        @clicked="switchContent"
      />
      <AccountMenu
        title="Bank Information"
        routeName="Bank"
        @clicked="switchContent"
      />
      <AccountMenu
        title="Security"
        routeName="Security"
        @clicked="switchContent"
      />
      <AccountMenu
        title="Subscription Plans"
        routeName="SubscriptionPlans"
        @clicked="switchContent"
      />
      <!-- <AccountMenu title="Live Chat" @clicked="$emit('show-live-chat')" /> -->
      <AccountMenu
        title="Configurations"
        routeName="Configuration"
        @clicked="switchContent"
      />
    </nav>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AccountMenu from "@/components/navigation/AccountMenu.vue";

export default {
  name: "AccountSidebar",
  components: { AccountMenu },
  emits: ["closeMenu", "switchLayout", "show-live-chat"],
  props: {
    layout: { type: String, default: () => "" },
  },
  data() {
    return {
      personalInformation: false,
      kycDocuments: false,
      bankInformation: false,
      security: false,
      subscriptionPlans: false,
      configuration: false,
      title: "Personal Information",
    };
  },
  computed: {
    ...mapState({
      orgDetails: (state) => state.organisationModule.organisationDetails,
    }),
  },
  created() {
    this.setFocus();
  },
  methods: {
    switchContent(title) {
      this.title = title;
      this.$emit("closeMenu");
      this.$emit("switchLayout", title);
      this.setFocus();
    },
    setFocus() {
      this.personalInformation = false;
      this.kycDocuments = false;
      this.bankInformation = false;
      this.security = false;
      this.subscriptionPlans = false;
      this.configuration = false;
      switch (this.title) {
        case "Personal Information":
          this.personalInformation = true;
          break;
        case "KYC Documents":
          this.kycDocuments = true;
          break;
        case "Bank Information":
          this.bankInformation = true;
          break;
        case "Security":
          this.security = true;
          break;
        case "Subscription Plans":
          this.subscriptionPlans = true;
          break;
        case "Configurations":
          this.configuration = true;
          break;
        default:
          break;
      }
    },
  },

  watch: {
    layout(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.title = this.layout;
        this.setFocus();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#account-sidebar {
  @include fadeIn;
}
</style>
