<template>
  <div class="tw-relative tw-flex tw-flex-col">
    <label :for="inputName">{{ label }}</label>
    <input
      :type="inputType"
      class="tw-text-sm tw-rounded tw-p-4 focus:tw-border-primary"
      :class="{
        'tw-border-error': showError,
      }"
      :value="pin"
      @input="updatePin"
      @blur="$emit('validate', pin)"
      @keypress="isNumber($event)"
      :placeholder="placeHolder"
      :disabled="disabled"
      required
    />
    <button type="button" @click="showPin" :disabled="!pin">
      <img
        src="@/assets/img/eye.svg"
        class="tw-absolute tw-right-4 tw-bottom-5"
        alt="show pin icon"
      />
      <img
        v-if="inputType === 'password'"
        src="@/assets/img/slash.svg"
        class="tw-absolute tw-right-3.5 tw-bottom-4"
        alt="show pin icon"
      />
    </button>
    <p class="tw-absolute tw--top-4 tw-right-px tw-text-error tw-text-xs">
      {{ errorMsg }}
    </p>
  </div>
</template>

<script>
import { isNumber } from "@/utils/helpers.js";

export default {
  name: "SecurityPinInput",

  emits: ["set", "validate"],

  props: {
    placeHolder: { type: String, default: "" },
    label: { type: String, default: "" },
    pin: { type: String, default: "" },
    error: { type: Object, default: () => ({}) },
    inputName: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
  },

  data() {
    return {
      inputType: "password",
      showError: false,
      errorMsg: "",
    };
  },

  methods: {
    isNumber,

    updatePin(event) {
      this.$emit("set", event.target.value);
    },

    checkError() {
      switch (this.error.$property) {
        case "confirmNewPin":
          this.showError = true;
          this.errorMsg = this.error.$message;
          break;

        default:
          this.showError = false;
          this.errorMsg = "";
          break;
      }
    },

    showPin() {
      this.inputType = this.inputType === "password" ? "text" : "password";
    },
  },

  watch: {
    error: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.checkError();
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>