<template>
  <div id="subscription-info">
    <div v-if="!loading && !showUpgradePlan" class="content tw-py-10">
      <h3
        class="tw-text-2xl tw-font-CircularStd-medium tw-font-medium tw-text-secondary"
      >
        Subscription Plans
      </h3>
      <p class="tw-text-gray-bg1 tw-text-sm tw-mb-12">
        Manage your subscription plan
      </p>
      <SubscriptionLimitStatus @show-plans="showUpgradePlan = true" />

      <div
        class="tw-bg-gray-lightest tw-rounded-lg tw-py-8 lg:tw-py-10 tw-px-4 lg:tw-px-10"
      >
        <div class="sm:tw-flex sm:tw-justify-between">
          <div class="tw-mb-6">
            <h3
              class="tw-text-xl tw-font-bold tw-text-gray-black tw-flex tw-gap-2 tw-items-center tw-mb-2"
            >
              {{ formatPlan(type) }}
              <span
                class="tw-rounded-[2px] tw-h-5 tw-w-max tw-px-2 tw-py-[2px] tw-font-CircularStd-medium tw-font-medium tw-text-xs"
                :class="[
                  plan?.status === 'Active'
                    ? 'tw-bg-[#27AE601F] tw-text-[#27AE60] '
                    : 'tw-bg-[rgba(226, 185, 59, 0.12)] tw-text-[#e2b93b]',
                ]"
                >{{ plan?.status }}</span
              >
            </h3>
            <p
              class="tw-text-black-light tw-text-xs"
              v-if="plan?.type.toLowerCase() === 'free'"
            >
              Maximum of {{ maxMembers }} members
            </p>
            <p
              v-if="type.toLowerCase() === 'free'"
              class="tw-text-black-light tw-text-xs tw-mt-2"
            >
              Maximum of 5 deals
            </p>
            <div v-if="plan?.type.toLowerCase() !== 'free'">
              <p
                class="tw-text-black-light tw-text-sm tw-mb-1 feature-list tw-flex tw-items-center"
                v-for="(feat, index) in getPlanFeatures(
                  plan?.type.toLowerCase()
                )"
                :key="index"
              >
                {{ feat }}
              </p>
            </div>
          </div>
          <div
            class="tw-text-xl tw-font-bold"
            v-if="plan?.percentage && plan?.type === 'Fixed'"
          >
            <span>{{ plan.percentage }}%</span
            ><span class="tw-text-gray">/Deal</span>
          </div>
          <div
            class="tw-text-xl tw-font-bold"
            v-else-if="plan?.type.toLowerCase() !== 'free'"
          >
            <span>{{ formatAmountToDollar(amount, 2, "USD") }}</span
            ><span v-if="duration" class="tw-text-gray">/{{ duration }}</span>
          </div>
        </div>
        <div
          class="tw-mt-12"
          :class="[
            plan?.type.toLowerCase() === 'free'
              ? 'upgrade-plan'
              : 'tw-flex tw-justify-between',
          ]"
        >
          <div
            class="tw-max-w-[130px]"
            v-if="plan?.type.toLowerCase() === 'free'"
          >
            <p class="tw-mb-2 tw-text-xs tw-text-black">
              {{ members }} of {{ maxMembers }} Members
            </p>
            <div class="gauge-container tw-mb-10 sm:tw-mb-0">
              <div
                class="gauge tw-relative tw-overflow-hidden tw-bg-primary  tw-h-1 tw-rounded"
              >
                <div
                  ref="fillGauge"
                  class="fill tw-absolute tw-bg-gray-dark tw-w-full tw-h-1 tw-rounded"
                ></div>
              </div>
            </div>
          </div>
          <button
            v-if="type.toLowerCase() !== 'free' && plan?.status === 'Active'"
            class="btn tw-w-full sm:tw-h-10 tw-rounded tw-text-sm tw-bg-[#FCE9E9] tw-text-[#C63434] tw-font-CircularStd-medium md:tw-w-[135px] tw-py-2 tw-ml-auto"
            @click="showConfirmModal = true"
          >
            <span>Terminate Plan</span>
          </button>
          <div
            v-else-if="
              type.toLowerCase() !== 'free' && plan?.status === 'Pending'
            "
            class="tw-flex tw-gap-4"
          >
            <button
              class="btn tw-w-full sm:tw-h-10 tw-rounded tw-text-sm tw-bg-[#FCE9E9] tw-text-[#C63434] hover:tw-bg-[#C63434] hover:tw-text-[#FCE9E9] tw-font-CircularStd-medium md:tw-w-[135px] tw-py-2  tw-ml-auto"
              @click="showConfirmModal = true"
            >
              <span>Terminate Plan</span>
            </button>
            <button
              class="btn tw-w-full sm:tw-h-10 tw-rounded tw-text-sm tw-bg-primary tw-text-white md:tw-w-40 lg:tw-w-28 tw-py-2"
              @click="showUpgradePlan = true"
            >
              <span>Pay</span>
            </button>
          </div>
          <button
            v-else
            class="btn tw-w-full sm:tw-h-10 tw-rounded tw-text-sm tw-bg-primary tw-text-white md:tw-w-40 lg:tw-w-48 tw-py-2"
            @click="showUpgradePlan = true"
          >
            <span>{{
              type.toLowerCase() === "free" ? "Upgrade Plan" : "Cancel"
            }}</span>
          </button>
        </div>
      </div>
      <div class="tw-mt-8">
        <p class="tw-text-gray tw-font-medium tw-text-right" v-if="expiration">
          Your next bill is for
          <b class="tw-text-secondary">{{
            formatAmountToDollar(nextBillAmount, 2, "USD")
          }}</b>
          on
          <b class="tw-text-secondary">{{ expiration }}</b>
        </p>
      </div>
    </div>
    <div v-if="loading" class="loading tw-relative tw-h-40">
      <Loader />
    </div>
    <ConfirmTermination
      v-if="showConfirmModal"
      :planId="plan._id"
      @fetch-plan="fetchPlans"
      @close-terminate-modal="showConfirmModal = false"
    />
    <UpgradePlan v-if="showUpgradePlan" :plans="plansArray" @back="goBack" />
  </div>
</template>

<script setup>
  import UpgradePlan from "@/components/account/UpgradePlan";
import ConfirmTermination from "@/components/account/subscription/ConfirmTermination.vue";
import SubscriptionLimitStatus from "@/components/account/subscription/SubscriptionLimitStatus.vue";

  import { useFundNotification } from "@/composables/notifyUserFunding";
import { formatAmountToDollar } from "@/utils/helpers.js";
import { getItem, setItem } from "@/utils/storage.js";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

  const store = useStore();
  const route = useRoute();
  const router = useRouter();

  const { checkFundingStatus, isSuccessful } = useFundNotification();

  const loading = ref(false);
  const showUpgradePlan = ref(false);
  const fillGauge = ref(null);
  const type = ref("");
  const amount = ref(0);
  const duration = ref("");
  const maxMembers = ref(0);
  const members = ref(0);
  const percentMembers = ref(0);
  const showConfirmModal = ref(false);

  const plans = computed(() => store.getters["organisationModule/plans"]);
  const plan = computed(() => store.getters["organisationModule/plan"]);
  const expiration = computed(() => {
    const plan = store.getters["organisationModule/plan"];
    if (plan) {
      return plan.expiration?.split("T")[0];
    }
  });
  const nextBillAmount = computed(() => {
    const planPrice = store.getters["organisationModule/plan"].amount;
    const members = getItem("org_details").members_count;
    return planPrice * members;
  });

  const plansArray = computed(() => {
    const array = [];
    const planCostArray = plans.value && Object.entries(plans.value);
    if (planCostArray) {
      planCostArray.map((costArray) => {
        const name = costArray[0];
        const price = costArray[1];
        const obj = {
          name,
          price: getPriceType(name, price),
          features: getPlanFeatures(name),
          limit: getPlanLimits(name),
          txFees: getTxnCharges(name)
        };
        if (name !== "free") {
          array.push(obj);
        }
      });
    }
    return array;
  });

  const getPriceType = (name, price) => {
    if (name.toLowerCase().includes("fixed")) {
      return `${price}%`;
    } else {
      return `$${price}`;
    }
  };

  const getPlanFeatures = (planName) => {
    let features = null;
    switch (planName) {
      case "fixed":
        features = [
          "Free SPV set-up for deals over $1,000,000.",
          "Unlimited number of members.",
          "Add members bulk operation.",
          "Unlimited deals.",
        ];
        break;
      case "flexible_month":
        features = [
          "Unlimited number of members",
          "Unlimited deals",
          "Add members bulk operation",
        ];
        break;
      case "flexible_annual":
        features = [
          "Unlimited number of members",
          "Unlimited deals",
          "Add members bulk operation",
        ];
        break;
      case "flexible":
        features = [
          "Unlimited number of members.",
          "Add members bulk operation.",
          "Unlimited deals.",
        ];
        break;
    }
    return features;
  };
  const getPlanLimits = (planName) => {
    let limit = null;
    switch (planName) {
      case "fixed":
        limit = "Commission on funds raised";
        break;
      case "flexible_month":
        limit = "Per member per month";
        break;
      case "flexible_annual":
        limit = "Per member per year";
        break;
    }
    return limit;
  };

  const getTxnCharges = (planName) => {
    let transactionCharge = null
    switch (planName) {
      case "fixed":
        transactionCharge = null;
        break;
      case "flexible_month":
        transactionCharge = "+ 1% transaction fee";
        break;
      case "flexible_annual":
        transactionCharge = null;
        break;
    }
    return transactionCharge
  }

  const goBack = () => {
    showUpgradePlan.value = false;
    fetchPlans();
    calculatePercent();
  };

  const formatPlan = (plan) => {
    let formattedPlan = null;
    switch (plan) {
      case "Free":
        formattedPlan = "Freemium";
        break;

      default:
        formattedPlan = plan;
        break;
    }
    return formattedPlan;
  };

  const fetchPlans = async () => {
    loading.value = true;
    showConfirmModal.value = false;
    try {
      const [orgPlan] = await Promise.all([
        store.dispatch("organisationModule/getOrganisationPlan"),
        store.dispatch("organisationModule/getPlans"),
      ]);
      const { data } = orgPlan.data;
      type.value = data.type;
      amount.value = data.amount;
      duration.value = data.duration;
      maxMembers.value = data.members_limit;
      loading.value = false;
      calculatePercent();
    } catch (error) {
      loading.value = false;
    }
  };

  const calculatePercent = () => {
    percentMembers.value = (members.value / maxMembers.value) * 100;
    nextTick(() => {
      if (fillGauge.value) {
        fillGauge.value.style.left = `${percentMembers.value}%`;
      }
    });
  };

  onMounted(async () => {
    members.value = getItem("org_details").members_count;
    fetchPlans();
    if (getItem("ge-subscription") && Object.keys(route.query).length === 0) {
      showUpgradePlan.value = true;
    }
    if (Object.keys(route.query).length !== 0) {
      await checkFundingStatus();
      if (isSuccessful.value) {
        router.push({ name: "SubscriptionPlans" });
      }
    }
  });
  watch(plan, (value) => {
    if (value.status === "Pending") {
      const payload = {
        product: value.product,
        type: value.type,
        duration: value.duration,
        amount: value.amount,
      };
      setItem("ge-subscription", payload);
    }
  });
  if (route.query?.plans) {
    showUpgradePlan.value = true;
  }
</script>

<style lang="scss" scoped>
  .feature-list::before {
    content: url("../../assets/img/slim-green-check.svg");
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-right: 6px;
  }
  #subscription-info {
    @include fadeIn;

    .content {
      .upgrade-plan {
        @media screen and (min-width: 640px) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 4rem;
          align-items: center;
        }

        .gauge-container {
          .gauge {
            .fill {
              top: 0;
              transition: all 0.5s ease-out;
            }
          }
        }

        .btn {
          justify-self: end;
          align-self: end;
        }
      }

      .switch {
        display: inline-block;
        position: relative;
        width: 38px;
        height: 20px;
        border-radius: 20px;
        background: #d8efff;
        transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
        vertical-align: middle;
        cursor: pointer;
      }

      .switch::before {
        content: "";
        position: absolute;
        top: 2px;
        left: 2px;
        width: 16px;
        height: 16px;
        background: #4d84ff;
        border-radius: 50%;
        transition: left 0.3s cubic-bezier(0.4, 0, 0.2, 1),
          background 0.28s cubic-bezier(0.4, 0, 0.2, 1),
          box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
      }

      input:checked + .switch {
        background: #d7ecd9;
      }

      input:checked + .switch::before {
        left: 20px;
        background: #27ae60;
      }
    }

    .loading {
      @media screen and (min-width: 1280px) {
        margin-right: 15%;
      }
    }
  }
</style>
