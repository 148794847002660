<template>
  <div id="kyc-dropdown">
    <div
      class="tw-border tw-rounded tw-px-4 tw-py-4 tw-mb-6 tw-cursor-pointer tw-border-[#F1F3F6] md:tw-min-h-[76px] md:tw-flex tw-items-center tw-flex-wrap"
    >
      <div
        class="tw-relative tw-flex tw-items-center tw-justify-between md:tw-w-full"
        @click="edit ? (openDropdown = !openDropdown) : null"
      >
        <div class="tw-flex tw-items-center tw-justify-between">
          <svg
            v-if="title === 'Bank Information'"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 18H20V20H0V18ZM2 10H4V17H2V10ZM7 10H9V17H7V10ZM11 10H13V17H11V10ZM16 10H18V17H16V10ZM0 5L10 0L20 5V9H0V5ZM10 6C10.2652 6 10.5196 5.89464 10.7071 5.70711C10.8946 5.51957 11 5.26522 11 5C11 4.73478 10.8946 4.48043 10.7071 4.29289C10.5196 4.10536 10.2652 4 10 4C9.73478 4 9.48043 4.10536 9.29289 4.29289C9.10536 4.48043 9 4.73478 9 5C9 5.26522 9.10536 5.51957 9.29289 5.70711C9.48043 5.89464 9.73478 6 10 6V6Z"
              fill="#4D84FF"
            />
          </svg>
          <svg
            v-if="title === 'Identification Documents'"
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 0.993C0.00183004 0.730378 0.1069 0.479017 0.292513 0.293218C0.478126 0.107418 0.72938 0.00209465 0.992 0H19.008C19.556 0 20 0.445 20 0.993V17.007C19.9982 17.2696 19.8931 17.521 19.7075 17.7068C19.5219 17.8926 19.2706 17.9979 19.008 18H0.992C0.728813 17.9997 0.476497 17.895 0.290489 17.7088C0.104482 17.5226 -1.33455e-07 17.2702 0 17.007V0.993ZM4 12V14H16V12H4ZM4 4V10H10V4H4ZM12 4V6H16V4H12ZM12 8V10H16V8H12ZM6 6H8V8H6V6Z"
              fill="#4D84FF"
            />
          </svg>
          <svg
            v-if="title === 'Business Documents'"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 2H8C4.5 2 3 4 3 7V17C3 20 4.5 22 8 22H16C19.5 22 21 20 21 17V7C21 4 19.5 2 16 2ZM8 12.25H12C12.41 12.25 12.75 12.59 12.75 13C12.75 13.41 12.41 13.75 12 13.75H8C7.59 13.75 7.25 13.41 7.25 13C7.25 12.59 7.59 12.25 8 12.25ZM16 17.75H8C7.59 17.75 7.25 17.41 7.25 17C7.25 16.59 7.59 16.25 8 16.25H16C16.41 16.25 16.75 16.59 16.75 17C16.75 17.41 16.41 17.75 16 17.75ZM18.5 9.25H16.5C14.98 9.25 13.75 8.02 13.75 6.5V4.5C13.75 4.09 14.09 3.75 14.5 3.75C14.91 3.75 15.25 4.09 15.25 4.5V6.5C15.25 7.19 15.81 7.75 16.5 7.75H18.5C18.91 7.75 19.25 8.09 19.25 8.5C19.25 8.91 18.91 9.25 18.5 9.25Z"
              fill="#4D84FF"
            />
          </svg>

          <svg
            v-if="title === 'Legal Documentation'"
            width="18"
            height="20"
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H17C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8946 0.48043 18 0.734784 18 1V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20ZM5 5V7H13V5H5ZM5 9V11H13V9H5ZM5 13V15H10V13H5Z"
              fill="#4D84FF"
            />
          </svg>
          <svg
            v-if="title === 'Email Verification'"
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 0H19C19.2652 0 19.5196 0.105357 19.7071 0.292893C19.8946 0.48043 20 0.734784 20 1V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM10.06 8.683L3.648 3.238L2.353 4.762L10.073 11.317L17.654 4.757L16.346 3.244L10.061 8.683H10.06Z"
              fill="#4D84FF"
            />
          </svg>
          <h3
            class="tw-mx-3 tw-text-xs md:tw-text-base tw-font-CircularStd-medium tw-font-medium"
          >
            {{ title }}
          </h3>
          <div :ref="statusDiv" class="">
            <span v-if="title === 'Bank Information'" class="tw-text-xs">
              {{ status }}
            </span>
            <span
              v-if="title === 'Identification Documents'"
              class="tw-text-xs"
              :class="[status.toLowerCase()]"
            >
              {{ status }}
            </span>
            <span
              v-if="title === 'Business Documents'"
              class="tw-text-xs"
              :class="[status.toLowerCase()]"
            >
              {{ status }}
            </span>
            <span v-if="title === 'Legal Documentation'" class="tw-text-xs">
              {{ status }}
            </span>
            <span v-if="title === 'Email Verification'" class="tw-text-xs">
              {{ status }}
            </span>
          </div>
        </div>
        <div
          v-if="edit"
          class="tw-absolute tw--top-1 tw-right-[-18px] tw-p-4 tw-cursor-pointer"
        >
          <svg
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.99992 2.82901L7.82792 1.33514e-05L9.24292 1.41401L4.99992 5.65701L0.75692 1.41401L2.17192 1.33514e-05L4.99992 2.82901Z"
              fill="#4F5877"
            />
          </svg>
        </div>
      </div>
      <transition
        name="dropdown"
        @enter="enter"
        @after-enter="afterEnter"
        @leave="leave"
      >
        <div v-show="openDropdown" class="tw-w-full">
          <slot name="content"></slot>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { abbreviate } from "@/utils/helpers.js";
export default {
  name: "KycDropdown",

  props: {
    title: { type: String, default: () => "", required: true },
    status: { type: String, default: () => "", required: true },
    edit: { type: Boolean, default: () => "", required: true },
  },

  data() {
    return {
      openDropdown: false,
      statusElement: [],
    };
  },

  computed: {
    ...mapState({
      orgDetails: (state) => state.organisationModule.organisationDetails,
    }),
  },

  mounted() {
    this.setStatus();
  },

  methods: {
    abbreviate,

    statusDiv(el) {
      this.statusElement.push(el);
    },

    setStatus() {
      this.statusElement.forEach((span) => {
        let status = span.querySelectorAll("span");
        if (status[0].innerHTML === "Not Linked" && this.edit === true) {
          status[0].classList.add("nill");
        }
        if (status[0].innerHTML === "Not Uploaded" && this.edit === true) {
          status[0].classList.add("nill");
        }
        if (status[0].innerHTML === "Approved") {
          status[0].classList.add("approved");
        }
        if (status[0].innerHTML === "Rejected") {
          status[0].classList.add("rejected");
        }
        if (status[0].innerHTML === "Pending" && this.edit === false) {
          status[0].classList.add("pending");
        }
        if (status[0].innerHTML === "Completed") {
          status[0].classList.add("approved");
        }
      });
    },

    enter(element) {
      element.style.height = "auto";
      let height = getComputedStyle(element).height;
      element.style.height = 0;
      getComputedStyle(element);
      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    },
    leave(element) {
      element.style.height = getComputedStyle(element).height;
      getComputedStyle(element);
      setTimeout(() => {
        element.style.height = 0;
      });
    },
  },

  watch: {
    orgDetails(newValue, oldValue) {
      deep: true;
      immediate: true;
      if (newValue !== oldValue) {
        setTimeout(() => {
          this.setStatus();
        }, 500);
        this.openDropdown = false;
      }
    },
    status: "setStatus",
  },
};
</script>

<style lang="scss" scoped>
#kyc-dropdown {
  .dropdown-enter-active,
  .dropdown-leave-active {
    transition: height 0.3s ease-in-out;
    overflow: hidden;
  }

  // Added with javascript
  .nill,
  .approved,
  .pending,
  .rejected {
    text-align: center;
    padding: 0.5rem;
    // margin-right: 1.4rem;
    border-radius: 5px;
  }

  .nill {
    color: $warning;
    background-color: #f7ebc6;
  }

  .approved {
    color: $success;
    background-color: $success-light;
  }

  .pending {
    color: #4d84ff;
    background-color: #f7f9fd;
  }

  .rejected {
    color: #eb5757;
    background-color: rgba(235, 87, 87, 0.12);
  }
}
</style>
