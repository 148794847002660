<template>
  <Modal
    class="tw-text-center"
    @close-modal="$emit('close-modal')"
    :show="show"
  >
    <div id="institution-modal-content">
      <template v-if="nordigenStepOne">
        <h1 class="tw-font-bold tw-text-lg">Select your country</h1>
        <div class="country-options__div">
          <select
            class="country__option tw-h-12 tw-px-4 tw-rounded"
            v-model="selectedCountry"
          >
            <option value="" selected disabled> Select Country</option>
            <option
              :value="country.iso2"
              v-for="(country, index) in countries"
              :key="index"
              >{{ country.name }}</option
            >
          </select>
        </div>
        <button
          class="tw-text-sm tw-font-medium tw-bg-primary tw-text-white tw-mt-6 tw-h-12 tw-rounded tw-w-full"
          :disabled="loadingInstitutions"
          @click="fetchInstitutions"
        >
          {{ loadingInstitutions ? "Please wait..." : "Continue" }}
        </button>
      </template>
      <template v-else>
        <h1 class="tw-font-bold tw-text-lg tw-mb-3">Select your bank:</h1>
        <div
          class="shadow tw-flex tw-relative tw-bg-white tw-rounded-lg tw-mb-10 tw-px-3 tw-gap-5"
        >
          <input
            class="tw-w-full tw-rounded tw-p-2 tw-text-primary"
            type="text"
            placeholder="Search..."
            v-model="institutionFilter"
          />
          <img
            src="data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMDAwMDAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciICB2aWV3Qm94PSIwIDAgMzAgMzAiIHdpZHRoPSIzMHB4IiBoZWlnaHQ9IjMwcHgiPjxwYXRoIGQ9Ik0gMTMgMyBDIDcuNDg4OTk3MSAzIDMgNy40ODg5OTcxIDMgMTMgQyAzIDE4LjUxMTAwMyA3LjQ4ODk5NzEgMjMgMTMgMjMgQyAxNS4zOTY1MDggMjMgMTcuNTk3Mzg1IDIyLjE0ODk4NiAxOS4zMjIyNjYgMjAuNzM2MzI4IEwgMjUuMjkyOTY5IDI2LjcwNzAzMSBBIDEuMDAwMSAxLjAwMDEgMCAxIDAgMjYuNzA3MDMxIDI1LjI5Mjk2OSBMIDIwLjczNjMyOCAxOS4zMjIyNjYgQyAyMi4xNDg5ODYgMTcuNTk3Mzg1IDIzIDE1LjM5NjUwOCAyMyAxMyBDIDIzIDcuNDg4OTk3MSAxOC41MTEwMDMgMyAxMyAzIHogTSAxMyA1IEMgMTcuNDMwMTIzIDUgMjEgOC41Njk4Nzc0IDIxIDEzIEMgMjEgMTcuNDMwMTIzIDE3LjQzMDEyMyAyMSAxMyAyMSBDIDguNTY5ODc3NCAyMSA1IDE3LjQzMDEyMyA1IDEzIEMgNSA4LjU2OTg3NzQgOC41Njk4Nzc0IDUgMTMgNSB6Ii8+PC9zdmc+"
            class="tw-w-6"
          />
        </div>
        <div
          v-if="filteredInstitutions.length"
          class="tw-overflow-y-scroll institutions"
        >
          <div
            class="tw-mb-6 tw-cursor-pointer"
            v-for="(institution, index) in filteredInstitutions"
            :key="index"
            @click="fetchNordigenLink(institution.id, institution.name)"
          >
            <div class="tw-flex tw-justify-start tw-items-center">
              <img
                :src="institution.logo"
                class="tw-w-8 tw-mr-4 tw-rounded-full"
              />
              <p class="tw-text-left">{{ institution.name }}</p>
            </div>
          </div>
        </div>
        <div v-else>
          <p>No banks found.</p>
        </div>
        <p class="tw-font-bold">{{ loadingMessage }}</p>
      </template>
    </div>
  </Modal>
</template>

<script>
  import nordigenApi from "@/utils/nordigen"
  import bankApi from "@/utils/mono"
  import { mapActions } from "vuex"
  import Modal from "../general/Modal"
  import responseHandler from "@/utils/responseHandler.js"
  export default {
    name: "NordigenBanks",
    data() {
      return {
        selectedCountry: "GB",
        institutions: [],
        institutionFilter: "",
        countries: [
          {
            name: "Austria",
            iso2: "AT",
          },
          {
            name: "Belgium",
            iso2: "BE",
          },
          {
            name: "Bulgaria",
            iso2: "BG",
          },
          {
            name: "Croatia",
            iso2: "HR",
          },
          {
            name: "Cyprus",
            iso2: "CY",
          },
          {
            name: "Czechia",
            iso2: "CZ",
          },
          {
            name: "Denmark",
            iso2: "DK",
          },
          {
            name: "Estonia",
            iso2: "EE",
          },
          {
            name: "Finland",
            iso2: "FI",
          },
          {
            name: "France",
            iso2: "FR",
          },
          {
            name: "Germany",
            iso2: "DE",
          },
          {
            name: "Greece",
            iso2: "GR",
          },
          {
            name: "Hungary",
            iso2: "HU",
          },
          {
            name: "Iceland",
            iso2: "IS",
          },
          {
            name: "Ireland",
            iso2: "IE",
          },
          {
            name: "Italy",
            iso2: "IT",
          },
          {
            name: "Latvia",
            iso2: "LV",
          },
          {
            name: "Liechtenstein",
            iso2: "LI",
          },
          {
            name: "Lithuania",
            iso2: "LT",
          },
          {
            name: "Luxembourg",
            iso2: "LU",
          },
          {
            name: "Malta",
            iso2: "MT",
          },
          {
            name: "Netherlands",
            iso2: "NL",
          },
          {
            name: "Norway",
            iso2: "NO",
          },
          {
            name: "Poland",
            iso2: "PL",
          },
          {
            name: "Portugal",
            iso2: "PT",
          },
          {
            name: "Romania",
            iso2: "RO",
          },
          {
            name: "Slovakia",
            iso2: "SK",
          },
          {
            name: "Spain",
            iso2: "ES",
          },
          {
            name: "Sweden",
            iso2: "SE",
          },
          {
            name: "United Kingdom",
            iso2: "GB",
          },
        ],
        loadingMessage: "",
        nordigenStepOne: true,
        loadingInstitutions: false,
      }
    },
    emits: ["close-modal", "trigger-loader"],
    components: {
      Modal,
    },
    props: {
      show: {
        type: Boolean,
        required: true,
      },
    },
    methods: {
      ...mapActions(["showToast"]),
      async fetchInstitutions() {
        this.loadingInstitutions = true
        try {
          const response = await nordigenApi.fetchInstitutions(
            this.selectedCountry
          )
          if (response.status === 201 || response.status === 200) {
            this.loadingInstitutions = false
            this.institutions = response.data.data
            this.nordigenStepOne = false
          } else {
            this.loadingInstitutions = false

            responseHandler.handleError(response.data.message)
          }
        } catch (error) {
          this.loadingInstitutions = false
          responseHandler.handleError(error)
        }
      },
      async fetchNordigenLink(id, name) {
        this.$emit("trigger-loader", true)
        this.loadingMessage = "Please wait..."
        let currentUrl = `${window.location.origin}${window.location.pathname}`
        try {
          const response = await bankApi.initiateBankConnection("nordigen", {
            id,
            name,
            redirectUri: currentUrl,
          })
          if (response.status === 201 || response.status === 200) {
            this.loadingMessage = "Redirecting..."
            window.location = response.data.data.link
            this.$emit("trigger-loader", false)
          } else {
            this.$emit("trigger-loader", false)
            responseHandler.handleError(response)
          }
        } catch (error) {
          this.$emit("trigger-loader", false)
          responseHandler.handleError(error)
        }
      },
    },
    computed: {
      filteredInstitutions() {
        if (!this.institutionFilter) {
          return this.institutions
        }
        return this.institutions.filter((institution) =>
          institution.name
            .toLowerCase()
            .includes(this.institutionFilter.toLowerCase())
        )
      },
    },
  }
</script>

<style lang="scss" scoped>
  .institutions {
    max-height: 150px;
  }

  #institution-modal-content {
    width: 300px;
  }

  .country {
    padding-top: 74px;
    &__text {
      max-width: 361px;
    }
    &-options__div {
      width: 100%;
      max-width: 340px;
      margin-top: 20px;
    }
    &__option {
      cursor: pointer;
      box-sizing: border-box;
      border: 1px solid #dde1e9;
      width: 100%;
      text-align: left;
      &:hover {
        border: 1px solid #177bdf;
      }
    }
  }

  .input-light {
    background-color: #e7ecf1;
    border: 1px solid #d7e0e2;
  }
  .input-dark {
    background-color: #2b3545;
    border: 2px solid #234160;
  }
</style>
