<template>
  <KycDropdown
    title="Identification Documents"
    :status="idStatus"
    :edit="updateId"
  >
    <template v-slot:content>
      <form @submit.prevent="uploadIdDocument">
        <div class="content">
          <p class="tw-text-gray tw-text-lg tw-mt-12 tw-mb-6">
            Provide only one of the following: International passport, National
            ID card, Driver’s License or Voter’s Card.
          </p>
          <div class="tw-relative tw-mb-6">
            <label for="document type"></label>
            <select
              name="documentType"
              id="documentType"
              class="tw-border tw-rounded tw-p-4 tw-pt-3 tw-w-full tw-cursor-pointer focus:tw-border-primary tw-border-gray-darkest"
              v-model="idDocument.documents[0].name"
              :disabled="iDLoading"
              required
            >
              <option value="" selected disabled>
                Select a document type
              </option>
              <option value="Passport">
                International passport
              </option>
              <option value="national identity card">National ID card</option>
              <option value="Driver’s License">Driver’s License</option>
              <option value="voter's card">Voter’s Card</option>
            </select>
            <img
              src="@/assets/img/solid-chevron-down.svg"
              class="tw-absolute tw-right-2 sm:tw-right-4 tw-top-6"
              alt="chevron icon"
            />
          </div>
          <DragNDrop
            accept="image/png, image/jpeg, image/jpg, .pdf"
            :currentValue="idDocument.documents[0].url"
            :startUpload="uploadIdDoc"
            :validate="validateUpload"
            @file-added="setDoc"
            @re-upload="uploadIdDoc = false"
            @upload-complete="submitIdDocument"
            @uploadError="idUploadError"
          >
            <p
              class="tw-text-sm tw-text-secondary tw-w-full tw-text-center tw-mb-2"
            >
              Drag and drop file
            </p>
          </DragNDrop>
          <button
            class="tw-w-full tw-rounded tw-text-sm tw-bg-primary tw-text-white md:tw-w-48 tw-py-4 tw-mt-2"
            :disabled="iDLoading"
          >
            <span v-if="!iDLoading">Submit</span>
            <img
              v-show="iDLoading"
              class="tw-m-auto"
              src="@/assets/img/spinner.svg"
              alt="animated spinner"
            />
          </button>
        </div>
      </form>
    </template>
  </KycDropdown>
</template>

<script>
  import KycDropdown from "@/components/account/KycDropdown";
  import { mapActions, mapGetters } from "vuex";
  import DragNDrop from "@/components/general/DragNDrop";

  export default {
    name: "IdentityDocument",
    data() {
      return {
        iDLoading: false,
        idStatus: "",
        updateId: false,
        idDocument: {
          documents: [
            {
              name: "",
              url: "",
              type: "identification",
            },
          ],
        },
        uploadIdDoc: false,
        validateUpload: false,
      };
    },
    components: {
      KycDropdown,
      DragNDrop,
    },

    computed: {
      ...mapGetters("organisationModule", ["organisationDetails"]),
    },
    watch: {
      organisationDetails: "updateStatus",
    },
    methods: {
      ...mapActions("organisationModule", ["submitKycDocument"]),
      ...mapActions(["showToast"]),
      updateStatus() {
        switch (this.organisationDetails.kyc.identityDocument) {
          case "":
            this.idStatus = "Not Uploaded";
            this.updateId = true;
            break;

          case "Approved":
            this.idStatus = "Approved";
            break;

          case "Rejected":
            this.idStatus = "Rejected";
            this.updateId = true;
            break;

          case "Pending":
            this.idStatus = "Pending";
            break;

          default:
            break;
        }
      },

      async submitIdDocument(url) {
        this.idDocument.documents[0].url = url;
        const response = await this.submitKycDocument(this.idDocument);
        if (response) {
          const { message } = response.data;
          this.showToast({
            description: message,
            display: true,
            type: "success",
          });
        }
        this.iDLoading = false;
        this.uploadIdDoc = false;
      },
      setDoc(doc) {
        this.idDocument.documents[0].url = doc;
      },

      idUploadError() {
        this.iDLoading = false;
        this.uploadIdDoc = false;
      },

      uploadIdDocument() {
        this.validateUpload = true;
        if (this.idDocument.documents[0].url.length > 0) {
          this.iDLoading = true;
          this.uploadIdDoc = true;
        }
      },
    },
  };
</script>

<style></style>
