<template>
  <div class="kyc-info__div">
    <div class="tw-py-10">
      <h3
        class="tw-text-2xl tw-font-CircularStd-medium tw-font-medium tw-text-secondary"
      >
        KYC Documents
      </h3>
      <p class="tw-text-gray-bg1 tw-text-sm">
        Manage your KYC Information
      </p>
    </div>
    <BankInfo />
    <IdentityDocument />
    <BusinessDocument />
    <LegalDocument />
    <KycDropdown
      title="Email Verification"
      :status="emailStatus"
      :edit="updateEmail"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import KycDropdown from "@/components/account/KycDropdown";
import DragNDrop from "@/components/general/DragNDrop";
import BankInfo from "./BankInfo";
import IdentityDocument from "./IdentityDocument";
import BusinessDocument from "./BusinessDocument";
import LegalDocument from "./LegalDocument";

export default {
  name: "KycInfo",

  components: {
    KycDropdown,
    DragNDrop,
    BankInfo,
    IdentityDocument,
    BusinessDocument,
    LegalDocument,
  },

  data() {
    return {
      emailStatus: "",
      updateEmail: false,
    };
  },

  computed: {
    ...mapState({
      orgDetails: (state) => state.organisationModule.organisationDetails,
    }),
  },

  created() {
    this.fetchOrgProfile();
  },

  methods: {
    ...mapActions("organisationModule", [
      "submitKycDocument",
      "getOrganisationProfile",
    ]),
    ...mapActions(["showToast"]),
    async fetchOrgProfile() {
      try {
        await this.getOrganisationProfile();
        this.updateStatus();
      } catch (error) {
        return error;
      }
    },

    updateStatus() {
      switch (this.orgDetails.kyc.email) {
        case true:
          this.emailStatus = "Completed";
          break;
        case false:
          this.emailStatus = "Pending";
          this.updateEmail = true;
          break;

        default:
          break;
      }
    },
  },

  watch: {
    orgDetails(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateStatus();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.kyc-info {
  &__div {
    @include fadeIn;
  }

  &-content__div {
    @media screen and (min-width: 768px) {
      max-width: 460px;
    }
  }
}
</style>
