<template>
  <KycDropdown
    title="Bank Information"
    :status="bankStatus"
    :edit="updateBankInfo"
  >
    <template v-slot:content>
      <div class="kyc-info-content__div">
        <h3 class="message tw-text-lg tw-mt-12 tw-mb-2 tw-text-black">
          You have not linked your bank account.
        </h3>
        <p class="tw-text-gray tw-text-sm tw-mb-4">
          Your bank account name must match what you used in creating your
          GetEquity account.
        </p>
        <p
          class="tw-flex tw-items-center tw-text-xs tw-text-primary tw-mb-4 tw-cursor-pointer"
          @click="showBankInfoModal = !showBankInfoModal"
        >
          <img
            src="https://res.cloudinary.com/djalafcj9/image/upload/v1636642080/getequityV2/info_fgtxjo.webp"
            class="tw-w-4 tw-h-4 tw-mr-2"
            alt="share"
            loading="lazy"
          /><span> Why we collect your bank information</span>
        </p>

        <div
          v-if="showBankInfoModal"
          class="tw-bg-gray-light tw-rounded tw-p-4 tw-mb-10"
        >
          <p class="tw-text-sm tw-text-black">
            GetEquity uses open banking providers to validate that the name you
            provided to us at sign up corresponds with your bank account's name.
          </p>
          <p class="tw-mt-2 tw-text-sm">
            This also guarantee that all your withdrawals from your Getequity
            wallet will always be deposited into your bank account that you have
            access to.
          </p>
          <p class="tw-mt-2 tw-text-sm">
            Getequity and these providers do not have access to your banking
            credentials.
          </p>
        </div>
        <router-link :to="{ name: 'Bank' }">
          <button
            class="tw-w-full tw-rounded tw-text-sm tw-bg-primary tw-text-white md:tw-w-48 tw-py-4"
          >
            <span>Link account</span>
          </button>
        </router-link>
      </div>
    </template>
  </KycDropdown>
</template>

<script>
  import KycDropdown from "@/components/account/KycDropdown";
  import { mapGetters } from "vuex";

  export default {
    name: "BankInfo",
    components: {
      KycDropdown,
    },
    data() {
      return {
        bankStatus: "",
        updateBankInfo: false,
        showBankInfoModal: false,
      };
    },

    computed: {
      ...mapGetters("organisationModule", ["organisationDetails"]),
    },
    watch: {
      organisationDetails: "updateStatus",
    },
    methods: {
      updateStatus() {
        switch (this.organisationDetails.kyc.bank) {
          case true:
            this.bankStatus = "Completed";
            break;
          case false:
            this.bankStatus = "Not Linked";
            this.updateBankInfo = true;
            break;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .kyc-info {
    &-content__div {
      @media screen and (min-width: 768px) {
        max-width: 460px;
      }
    }
  }
</style>
