<template>
  <KycDropdown title="Business Documents" :status="documentStatus" :edit="true">
    <template v-slot:content>
      <form @submit.prevent="uploadBusinessDocument">
        <div>
          <div
            class="tw-mt-14"
            v-for="(document, index) in documentPayload"
            :key="document.name + index"
          >
            <div class="tw-mt-6 md:tw-mt-0">
              <SelectInput
                v-show="false"
                class="tw-mb-4"
                label="Upload Pitch Deck & Other Supporting Document"
                name="document name"
                id="document_name"
                defaltOption="Select document name"
                :option="document.name"
                :showLabel="true"
                :list="documentList"
                :required="false"
                @set="setDocumentType($event, index)"
              />
              <TextInput
                class="tw-mb-4"
                placeHolder="Document name"
                label="Upload Document"
                type="text"
                border="tw-border-gray-dark"
                fieldType="text"
                id="document_name"
                name="document_name"
                :showLabel="true"
                :text="document.name"
                @set="setDocumentType($event, index)"
                @valid="documentNameValid"
              />
              <DragNDrop
                accept=".pdf"
                :currentValue="documentPayload[index].url"
                :startUpload="uploadDocument[index].doc"
                :hasMaxWidth="false"
                @file-added="setDocument($event, index)"
                @reupload="uploadDocument[index].doc = false"
                @upload-complete="submitDocument($event, index)"
                @uploadError="uploadDocumentError(index)"
              >
              </DragNDrop>
              <div
                v-if="documentPayload.length > 1"
                class="tw-w-42 tw-flex tw-self-center tw-mt-8"
                @click="removeDocument(document, index)"
              >
                <img src="@/assets/img/trash.svg" class="tw-w-5 tw-h-5" />
                <span class="tw-text-sm tw-ml-1">Remove Document</span>
              </div>
            </div>
          </div>
          <div
            class="tw-w-44 tw-flex tw-self-center tw-mt-8"
            @click="addDocument"
          >
            <img src="@/assets/img/add-circle.svg" class="tw-w-5 tw-h-5" />
            <span class="tw-text-sm tw-ml-1">Add more documents</span>
          </div>
          <div class="tw-flex tw-justify-end">
            <div class="md:tw-w-48">
              <Btn class="tw-mt-6" :loading="documentLoading" title="Submit" />
            </div>
          </div>
        </div>
      </form>
    </template>
  </KycDropdown>
</template>

<script setup>
  import { ref, computed, onMounted, watch } from "vue";
  import { useStore } from "vuex";
  import KycDropdown from "@/components/account/KycDropdown";
  import DragNDrop from "@/components/general/DragNDrop";
  import TextInput from "@/components/general/TextInput.vue";
  import SelectInput from "@/components/general/SelectInput.vue";
  import Btn from "@/components/general/BtnComponent.vue";

  const store = useStore();

  const documentStatus = ref("");
  const documentLoading = ref(false);
  const otherDocumentName = ref("");
  const otherDocumentIndex = ref(0);
  const documentList = ref(["policy doc", "sales report"]);
  const count = ref(0);
  const uploadDocument = ref([{ doc: false }]);
  const documentPayload = ref([{ type: "", name: "", url: "" }]);
  const validInput = ref({
    documentName: false,
  });

  onMounted(() => {
    updateDocument();
  });

  const orgDetails = computed(() => {
    return store.getters["organisationModule/organisationDetails"];
  });

  const submitDocument = async (url, index) => {
    documentPayload.value.map((document, docIndex) => {
      if (index === docIndex) {
        document.url = url;
      }
      if (document.type === "Others") {
        document.type = "Other";
      }
    });
    count.value++;
    if (documentPayload.value.length === count.value) {
      // const response = await this.updateAsset({
      //   data: payload,
      //   id: this.$route.query.asset
      //     ? this.$route.query.asset
      //     : this.assetDetails._id,
      // });
      await store.dispatch("organisationModule/updateKycDocument", {
        documents: documentPayload.value,
        type: "Business",
      });
      // if (response) this.$emit("dropdown-title", "Location");
      uploadDocument.value.map((document, index) => {
        uploadDocument.value[index].doc = false;
      });
      count.value = 0;
      documentLoading.value = false;
    }
  };

  const uploadBusinessDocument = () => {
    setTimeout(() => {
      uploadDocument.value.map((document, index) => {
        uploadDocument.value[index].doc = true;
        documentLoading.value = true;
      });
    }, 500);
  };

  const addDocument = () => {
    documentPayload.value.push({ type: "", name: "", url: "" });
    uploadDocument.value.push({ doc: false });
  };

  const removeDocument = async (document, index) => {
    documentPayload.value.splice(index, 1);
    uploadDocument.value.splice(index, 1);
    if (!document.url) return;
    await store.dispatch("organisationModule/deleteDocument", {
      docId: document._id,
      assetId: orgDetails.value._id,
    });
  };

  const setDocument = (val, index) => {
    documentPayload.value[index].url = val;
  };

  const setDocumentType = (option, index) => {
    documentPayload.value[index].type = "Other";
    otherDocumentName.value = option;
    otherDocumentIndex.value = index;
  };

  const documentNameValid = (valid) => {
    validInput.value.documentName = valid;
    documentPayload.value[otherDocumentIndex.value].name =
      otherDocumentName.value;
  };

  const uploadDocumentError = (index) => {
    documentLoading.value = false;
    uploadDocument.value[index].doc = false;
  };

  const updateDocument = () => {
    if (orgDetails.value.documents?.length) {
      let newDocuments = [];
      let newUploadDocument = [];
      orgDetails.value.documents.map((document, index) => {
        if (document.type === "Other") {
          let obj = {
            type: document?.type,
            name: document?.name,
            url: document?.url,
            _id: document?._id,
          };
          newDocuments.push(obj);
          newUploadDocument.push({ doc: false });
        }
      });
      uploadDocument.value = newUploadDocument;
      documentPayload.value = newDocuments;
    }
  };

  watch(orgDetails, () => {
    updateDocument();
  });
</script>

<style lang="scss" scoped></style>
