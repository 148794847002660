<template>
  <KycDropdown
    title="Legal Documentation"
    :status="legalStatus"
    :edit="updateLegal"
  >
    <template v-slot:content>
      <form @submit.prevent="checkFormValidation" class="content tw-mt-12">
        <div
          class="tw-mb-4"
          v-for="(doc, index) in legalDocument.documents"
          :key="doc.name"
        >
          <label for="cac document" class="tw-text-lg tw-text-gray">
            Upload {{ doc.name }}
            <span class="tw-text-red-error" v-if="doc.required">*</span>
          </label>
          <DragNDrop
            class="tw-mt-2"
            accept="image/png, image/jpeg, image/jpg, .pdf"
            identifier="legalDocumentUpload"
            :currentValue="doc.url"
            :startUpload="uploadLegalDoc"
            :validate="validate"
            @is-valid="handleValidation"
            @re-upload="uploadLegalDoc = false"
            @file-added="setDoc($event, index)"
            @upload-complete="setDocUrl($event, index)"
            @uploadError="legalUploadError"
            :required="doc.required"
          >
            <p
              class="tw-text-sm tw-text-secondary tw-w-full tw-text-center tw-mb-2"
            >
              Drag and drop file
            </p>
          </DragNDrop>
        </div>
        <button
          class="tw-w-full tw-mt-8 tw-rounded tw-text-sm tw-bg-primary tw-text-white md:tw-w-48 tw-py-4"
          :disabled="legalLoading"
        >
          <span v-if="!legalLoading">Submit</span>
          <img
            v-show="legalLoading"
            class="tw-m-auto"
            src="@/assets/img/spinner.svg"
            alt="animated spinner"
          />
        </button>
      </form>
    </template>
  </KycDropdown>
</template>

<script setup>
  import { useValidation } from "@/composables/formValidation";
  import KycDropdown from "@/components/account/KycDropdown";
  import DragNDrop from "@/components/general/DragNDrop";
  import { useStore } from "vuex";
  import { computed, reactive, ref, watch } from "vue";
  import { useUploadCounter } from "@/composables/dragDropUploadCounter";
  const store = useStore();

  const {
    fields,
    handleValidation,
    checkFormValidation,
    validate,
    canSubmit,
  } = useValidation();

  const { filesToUpload, getNumberOfUploadFields } = useUploadCounter();

  fields.value = 3;

  const legalLoading = ref(false);
  const legalStatus = ref("");
  const updateLegal = ref(false);
  const legalDocument = reactive({
    documents: [
      {
        name: "Incorporation Document",
        url: "",
        type: "legal",
        required: true,
      },
      {
        name: "CAC 2 or Shares Allotment Document",
        url: "",
        type: "legal",
        required: false,
      },
      {
        name: "Memorandum of Association",
        url: "",
        type: "legal",
        required: false,
      },
    ],
  });
  const uploaded = ref([]);
  const uploadLegalDoc = ref(false);
  const legalUploadError = ref("");

  const organisationDetails = computed(
    () => store.getters["organisationModule/organisationDetails"]
  );

  const updateStatus = () => {
    switch (organisationDetails.value.kyc.legalDocument) {
      case "":
        legalStatus.value = "Not Uploaded";
        updateLegal.value = true;
        break;

      case "Approved":
        legalStatus.value = "Approved";
        break;

      case "Rejected":
        legalStatus.value = "Rejected";
        updateLegal.value = true;
        break;

      case "Pending":
        legalStatus.value = "Pending";
        break;
    }
  };

  const setDoc = (doc, index) => {
    legalDocument.documents[index].url = doc;
  };
  const setDocUrl = (url, index) => {
    setDoc(url, index);
    uploaded.value.push(true);
    if (uploaded.value.length === filesToUpload.value) {
      submitLegalDocument();
    }
  };
  const submitLegalDocument = async () => {
    legalDocument.documents.forEach((doc) => {
      delete doc.required;
    });
    const legal = legalDocument.documents.filter((doc) => {
      return doc.url;
    });
    const response = await store.dispatch(
      "organisationModule/submitKycDocument",
      { documents: legal }
    );
    if (response) {
      const { message } = response.data;
      store.dispatch("showToast", {
        description: message,
        display: true,
        type: "success",
      });
      uploadLegalDoc.value = false;
    }
    legalLoading.value = false;
  };
  watch(validate, (value) => {
    if (value) {
      getNumberOfUploadFields("legalDocumentUpload");
    }
  });
  watch(organisationDetails, () => {
    updateStatus();
  });
  watch(canSubmit, (value) => {
    if (value) {
      uploaded.value = [];
      uploadLegalDoc.value = true;
      legalLoading.value = true;
    }
  });
  if (organisationDetails.value) {
    updateStatus();
  }
</script>

<style></style>
