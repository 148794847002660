<template>
  <section>
    <PersonalInfo :orgDetails="organisationDetails" />
  </section>
</template>

<script>
  import PersonalInfo from "@/components/account/PersonalInfo";
  import { mapGetters } from "vuex";
  export default {
    name: "ProfileInfo",
    components: {
      PersonalInfo,
    },

    computed: {
      ...mapGetters("organisationModule", ["organisationDetails"]),
    },
  };
</script>
