<template>
  <div id="upgrade-plan" class="tw-relative">
    <Loader v-if="loading" />
    <form @submit.prevent="" class="content tw-py-10">
      <h3 class="tw-text-2xl">Upgrade your plan</h3>
      <p class="tw-text-gray-bg1 tw-text-sm tw-mb-12">
        Manage your subscription plan
      </p>
      <p
        class="tw-absolute tw-top-12 tw-right-0 tw-hidden lg:tw-block tw-text-black-light tw-text-sm hover:tw-text-primary tw-cursor-pointer"
        @click="$emit('back')"
      >
        Back to plan
      </p>
      <div
        class="tw-absolute tw-top-12 sm:tw-top-12 tw-right-0 lg:tw-hidden tw-py-0.5 tw-px-2 tw-border tw-border-black-light tw-rounded"
        @click="$emit('back')"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.02698 11.9929L5.26242 16.2426L6.67902 14.8308L4.85766 13.0033L22.9731 13.0012L22.9728 11.0012L4.85309 11.0033L6.6886 9.17398L5.27677 7.75739L1.02698 11.9929Z"
            fill="#4F5877"
          />
        </svg>
      </div>
      <div
        v-for="(plan, index) in plans"
        :key="index"
        class="tw-w-full tw-max-w-[680px] tw-mx-auto lg:tw-flex lg:tw-justify-between lg:tw-items-stretch tw-bg-white tw-mb-12 tw-p-8 tw-rounded-[4px] tw-border tw-border-[#E6EDFF]  plan-card lg:tw-h-[300px]"
        v-show="!plan.name.toLowerCase().includes('esop')"
      >
        <div
          class="tw-mt-2 lg:tw-mt-0 tw-w-full lg:tw-max-w-[250px] lg:tw-h-full"
        >
          <h3
            class="tw-text-lg tw-mb-2 tw-font-CircularStd-medium tw-font-medium tw-text-black-dark"
          >
            {{ capitalizeEachWord(removeUnderscore(plan.name)) }}
          </h3>
          <p
            class="tw-text-gray tw-font-bold tw-tracking-[-1.21px] tw-text-[#060809] tw-text-[2.813rem]"
          >
            {{ plan.price }} <span class="tw-text-sm tw-text-gray tw-font-normal">{{ plan.txFees }}</span>
          </p>
          <p
            class="tw-text-gray-light2 tw-font-CircularStd-medium tw-font-medium tw-tracking-[-0.12px]"
          >
            {{ plan.limit }}
          </p>
          <div
            class="tw-flex tw-gap-4 tw-mt-8 lg:tw-mt-0"
            v-if="plan.name === 'Flexible Plan'"
          >
            <label
              for="monthly"
              class="tw-text-sm tw-tracking[-0.12px] tw-text-black-dark tw-inline-flex tw-items-center tw-gap-2"
            >
              <input
                type="radio"
                name="monthly"
                id="monthly"
                class="tw-w-4 tw-h-4 tw-accent-primary input-field"
              />Monthly
            </label>
            <label
              for="monthly"
              class="tw-text-sm tw-tracking[-0.12px] tw-text-black-dark tw-inline-flex tw-items-center tw-gap-2"
            >
              <input
                type="radio"
                name="yearly"
                id="yearly"
                class="tw-w-4 tw-h-4 tw-accent-primary input-field"
              />Yearly
            </label>
          </div>
        </div>
        <div
          class=" tw-w-full lg:tw-max-w-[250px] tw-mt-8 lg:tw-mt-0 tw-flex tw-flex-col tw-justify-between"
        >
          <ul>
            <li
              class="tw-mb-4 tw-tracking-[-0.2px] tw-text-black tw-font-CircularStd-medium tw-font-medium tw-text-normal feature-list tw-leading-6 tw-flex"
              v-for="(feat, index) in plan.features"
              :key="index"
            >
              <p>{{ feat }}</p>
            </li>
          </ul>
          <button
            v-if="plan.status !== 'Pending'"
            @click="getPlanPayload(plan)"
            class="tw-bg-primary tw-rounded tw-h-12 tw-w-full lg:tw-max-w-[250px] tw-flex tw-justify-center tw-items-center tw-text-white tw-tracking-[-0.12px] tw-mt-1"
          >
            <span v-if="loading">
              <img src="@/assets/img/spinner.svg" />
            </span>
            <template v-else>Select Plan</template>
          </button>
        </div>
      </div>
    </form>
    <SubscriptionConfirmation
      @close-modal="showSubscriptionConfirmation = false"
      v-if="showSubscriptionConfirmation"
      :plan="payload"
    />
  </div>
</template>

<script setup>
  import { capitalizeEachWord, removeUnderscore } from "@/utils/helpers.js";
import { getItem, setItem } from "@/utils/storage";
import { computed, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import SubscriptionConfirmation from "./subscription/SubscriptionConfirmation.vue";

  const emit = defineEmits(["back"]);
  defineProps({
    plans: {
      type: Array,
      required: true,
    },
  });

  const store = useStore();

  const loading = ref(false);
  const payload = reactive({
    product: "DEALROOM",
    type: "",
    duration: "",
    amount: "",
  });
  const showSubscriptionConfirmation = ref(false);

  const plan = computed(() => store.getters["organisationModule/plan"]);

  const getOrgDetails = async () => {
    await Promise.all([
      store.dispatch("organisationModule/getAllMembers", {
        page: 1,
        limit: 10,
      }),
      store.dispatch("organisationModule/getWalletBalance"),
    ]);
  };

  const getPlanPayload = ({ name, price }) => {
    switch (name) {
      case "fixed":
        payload.type = name;
        delete payload.amount;
        payload.percentage = price.split("%")[0];
        delete payload.duration;
        delete payload.duration;
        break;
      case "flexible_month":
        payload.duration = "Monthly";
        payload.type = name.split("_")[0];
        payload.amount = price.split("$")[1];
        break;
      case "flexible_annual":
        payload.duration = "Annually";
        payload.type = name.split("_")[0];
        payload.amount = price.split("$")[1];
        break;
    }
    if (payload.type === "fixed") {
      showSubscriptionConfirmation.value = true;
    } else {
      initiatePlan();
    }
  };

  const initiatePlan = async () => {
    loading.value = true;
    payload.type = capitalizeEachWord(payload.type);
    await store.dispatch("organisationModule/initiatePlan", payload);
    await store.dispatch("organisationModule/getOrganisationPlan");
    if (payload.type.toLowerCase() === "flexible") {
      showSubscriptionConfirmation.value = true;
      setItem("ge-subscription", payload);
    } else {
      emit("back");
    }
    loading.value = false;
  };

  getOrgDetails();
  if (getItem("ge-subscription")) {
    const { amount, type, duration } = getItem("ge-subscription");
    payload.amount = amount;
    payload.type = type;
    payload.duration = duration;
    showSubscriptionConfirmation.value = true;
  }
  watch(showSubscriptionConfirmation, (value) => {
    if (!value && plan.value.status === "Pending") {
      emit("back");
    }
  });
  watch(plan, (value) => {
    if (
      value &&
      value.status === "Active" &&
      value.type.toLowerCase() !== "free"
    ) {
      emit("back");
    }
  });
</script>

<style lang="scss" scoped>
  #upgrade-plan {
    @include fadeIn;

    .content {
      @media screen and (min-width: 768px) {
        max-width: 768px;
      }

      .switch {
        display: inline-block;
        position: relative;
        width: 38px;
        height: 20px;
        border-radius: 20px;
        background: #d8efff;
        transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
        vertical-align: middle;
        cursor: pointer;
      }

      .switch::before {
        content: "";
        position: absolute;
        top: 2px;
        left: 2px;
        width: 16px;
        height: 16px;
        background: #4d84ff;
        border-radius: 50%;
        transition: left 0.3s cubic-bezier(0.4, 0, 0.2, 1),
          background 0.28s cubic-bezier(0.4, 0, 0.2, 1),
          box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
      }

      input:checked + .switch {
        background: #d7ecd9;
      }

      input:checked + .switch::before {
        left: 20px;
        background: #27ae60;
      }

      .plan-card {
        box-shadow: 0px 2px 6px rgba(11, 40, 105, 0.08);
      }

      .feature-list::before {
        content: url("../../assets/img/slim-green-check.svg");
        width: 24px;
        height: 24px;
        display: inline-block;
        margin-right: 6px;
      }

      .input-field {
        @apply tw-border-primary;
      }
    }
  }
</style>
