import axios from "axios"
import responseHandler from "@/utils/responseHandler.js"
import api from "@/utils/api"
const methods = {
    /**
     * Fetch nordigen institutions
     * @param {string} countryISO2
     */
    async fetchInstitutions(countryISO2) {
        try {
            const response = await axios.get(
                `${process.env.VUE_APP_UTILITY_API}/nordigen/banks?country=${countryISO2}`, {
                    headers: api.getHeader(),
                }
            )
            return response
        } catch (e) {
            responseHandler.handleError(e)
            return e
        }
    },
}

export default methods