<template>
  <section>
    <KycInfo />
  </section>
</template>

<script>
  import KycInfo from "@/components/account/KycInfo";
  export default {
    name: "KycView",
    components: {
      KycInfo,
    },
  };
</script>

<style></style>
