import { ref } from "vue";
export function useUploadCounter() {
    const filesToUpload = ref(0);
    const getNumberOfUploadFields = (formClassName) => {
        const dragDrop = Array.from(document.getElementsByClassName(formClassName));
        const filesLength = dragDrop.length;
        filesToUpload.value = filesLength;
    };

    return { filesToUpload, getNumberOfUploadFields };
}