<template>
  <div id="security-info">
    <div v-if="!showChangePin" class="content tw-py-10">
      <h3 class="tw-text-2xl tw-font-CircularStd-medium tw-font-medium">
        Security
      </h3>
      <p class="tw-text-gray-bg1 tw-text-sm tw-mb-12">
        Secure your account
      </p>
      <button
        class="tw-w-full tw-cursor-pointer tw-bg-gray-lightest tw-rounded tw-p-4 tw-mb-6 tw-flex tw-justify-between"
        @click="showChangePin = true"
      >
        <p class="tw-flex">PIN</p>
        <img src="@/assets/img/caret-right-grey.svg" />
      </button>
      <button
        class="tw-w-full tw-cursor-pointer tw-bg-gray-lightest tw-rounded tw-p-4 tw-flex tw-justify-between"
        @click="sendLink"
      >
        <p v-show="!passwordLoading" class="tw-flex">Password</p>
        <img src="@/assets/img/caret-right-grey.svg" />
        <img
          v-show="passwordLoading"
          class="tw-m-auto"
          src="@/assets/img/spinner.svg"
          alt="animated spinner"
        />
      </button>
      <!-- <div class="tw-mt-8">
        <div class="tw-flex tw-items-center tw-mb-2">
          <input
            type="checkbox"
            class="checkbox tw-absolute tw-w-10 tw-h-5 tw-cursor-pointer tw-opacity-0 tw-z-20"
            name="checkbox"
            id="2fa-checkbox"
          />
          <label for="2 factor authentification" class="switch"></label>
          <p class="tw-text-lg tw-ml-4">2 Factor Authentification</p>
        </div>
        <p class="tw-text-black-light tw-leading-6 tw-text-sm">
          Secure your account with 2FA security. When it is activated you will
          need to enter not only your password, but also a special code using
          app. You can receive this code by in mobile app.
        </p>
      </div> -->
    </div>
    <ChangePin v-if="showChangePin" @back="goBack" />
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import ChangePin from "@/components/account/ChangePin.vue";
  import { getItem } from "@/utils/storage.js";

  export default {
    name: "SecurityInfo",

    components: { ChangePin },

    data() {
      return {
        passwordLoading: false,
        showChangePin: false,
      };
    },

    methods: {
      ...mapActions("organisationModule", ["resetPasswordLink"]),
      ...mapActions(["showToast"]),

      goBack() {
        this.showChangePin = false;
      },

      async sendLink() {
        this.passwordLoading = true;
        const details = getItem("org_details");
        const payload = { email: details.email };
        let response = await this.resetPasswordLink(payload);
        if (response) {
          const { message } = response.data;
          this.showToast({
            description: message,
            display: true,
            type: "success",
          });
          this.passwordLoading = false;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  #security-info {
    @include fadeIn;

    .content {
      @media screen and (min-width: 768px) {
        max-width: 460px;
      }

      .switch {
        display: inline-block;
        position: relative;
        width: 38px;
        height: 20px;
        border-radius: 20px;
        background: #d8efff;
        transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
        vertical-align: middle;
        cursor: pointer;
      }

      .switch::before {
        content: "";
        position: absolute;
        top: 2px;
        left: 2px;
        width: 16px;
        height: 16px;
        background: #4d84ff;
        border-radius: 50%;
        transition: left 0.3s cubic-bezier(0.4, 0, 0.2, 1),
          background 0.28s cubic-bezier(0.4, 0, 0.2, 1),
          box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
      }

      input:checked + .switch {
        background: #d7ecd9;
      }

      input:checked + .switch::before {
        left: 20px;
        background: #27ae60;
      }
    }
  }
</style>
