<template>
  <div class="configuration tw-pt-12 tw-relative">
    <Loader v-if="loading" />
    <h1 class="tw-text-2xl">
      Configurations
    </h1>
    <p class="tw-text-gray-bg1 tw-text-sm tw-mb-10">Manage your fees here</p>
    <ConfigurationForm
      v-if="subscriptionInfo"
      @submit-configuration="updateConfiguration"
    />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import { getItem } from "@/utils/storage.js";
  import ConfigurationForm from "@/components/account/configuration/ConfigurationForm";
  export default {
    name: "Configuration",
    data() {
      return {
        loading: true,
        subscription: {
          carry_percentage: "",
          monthly: "",
          annually: "",
          preview_deal: false,
        },
      };
    },
    components: {
      ConfigurationForm,
    },
    computed: {
      ...mapGetters("Account", ["subscriptionInfo"]),
    },
    created() {
      this.fetchData();
    },
    methods: {
      ...mapActions("Account", ["fetchSubscriptions", "updateSubscription"]),
      ...mapActions("organisationModule", [
        "getOrganisationProfile",
        "updateOrgDetails",
      ]),
      ...mapActions(["showToast"]),
      async fetchData() {
        const orgInfo = getItem("org_details");
        const orgId = orgInfo._id;
        try {
          await Promise.all([
            this.fetchSubscriptions(),
            this.getOrganisationProfile(orgId),
          ]);
          this.loading = false;
          this.subscription.carry_percentage = this.subscriptionInfo.carry;
          this.subscription.monthly = this.subscriptionInfo.monthly;
          this.subscription.preview_deal = this.subscriptionInfo.preview_deal;
          this.subscription.annually = this.subscriptionInfo.annually;
        } catch (error) {
          this.loading = false;
        }
      },
      async updateConfiguration(subscription) {
        this.subscription = subscription;
        this.loading = true;
        try {
          const response = await this.updateSubscription(subscription);
          const { message } = response.data;
          this.showToast({
            description: message,
            display: true,
            type: "success",
          });
          this.fetchData();
        } catch (error) {
          this.loading = false;
          return error;
        }
      },
      setPreview(boolean) {
        this.subscription.preview_deal = boolean;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .configuration {
    @include fadeIn;
    height: 70vh;
    overflow-y: scroll;
    @media (min-width: 768px) {
      height: 100vh;
    }
  }

  .required {
    color: indianred;
  }
</style>
