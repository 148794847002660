<template>
  <div class="bank-info tw-pt-12">
    <Loader v-if="loading" />
    <h1 class="tw-text-2xl tw-font-CircularStd-medium tw-font-medium">
      Bank Information
    </h1>
    <p class="tw-text-gray-bg1 tw-text-sm tw-mb-10">
      Edit your bank information
    </p>
    <ul
      class="bank-info__list tw-flex tw-flex-row tw-flex-wrap tw-mt-12"
      v-if="bankAccounts && bankAccounts.length > 0"
    >
      <li
        class="bank-info__item tw-px-6 tw-py-4"
        v-for="bank in bankAccounts"
        :key="bank._id"
      >
        <img
          @error="setFallbackImage"
          :src="bank.image"
          :alt="bank.name"
          class="bank-info__image tw-mb-4"
        />
        <p class="tw-mb-2 tw-text-black tw-font-medium tw-truncate">
          {{ bank.number }}
        </p>
        <p class="tw-text-black-light tw-text-sm tw-mb-2">
          {{ bank.name }}
        </p>
        <button
          class="tw-text-primary tw-w-28 tw-text-left tw-font-medium tw-rounded tw-h-8 hover:tw-bg-white hover:tw-text-primary"
          @click="removeBank(bank._id)"
        >
          Delete
        </button>
      </li>
      <button
        class="bank-info__item tw-flex tw-flex-col tw-justify-center tw-px-6 tw-py-4"
        @click="show = true"
      >
        <div class="tw-block tw-mx-auto tw-h-8 tw-w-8 tw-rounded-full tw-mb-2">
          <img
            class="tw-w-full tw-h-full"
            src="@/assets/img/add-circle-blue.svg"
            alt="add icon"
          />
        </div>
        <p
          class="tw-text-black-light tw-text-xs tw-text-center tw-mb-2 tw-mt-2 tw-w-full"
        >
          Connect a Bank
        </p>
      </button>
    </ul>

    <button
      @click="show = true"
      v-else
      class="bank-info__item tw-flex tw-flex-col tw-justify-center tw-px-6 tw-py-4 tw-mt-10"
    >
      <div class="tw-block tw-mx-auto tw-h-8 tw-w-8 tw-rounded-full tw-mb-2">
        <img
          class="tw-w-full tw-h-full"
          src="@/assets/img/add-circle-blue.svg"
          alt="add icon"
        />
      </div>
      <p
        class="tw-text-black-light tw-text-xs tw-text-center tw-mb-2 tw-mt-2 tw-w-full"
      >
        Connect a Bank
      </p>
    </button>
    <div v-if="entityCard.length !== 0" class="tw-my-12">
      <h1 class="tw-text-2xl">
        Manage Cards
      </h1>
      <p class="tw-text-gray-bg1 tw-text-sm tw-mb-10">
        Manage your card information
      </p>
      <div class="lg:tw-grid lg:tw-grid-cols-2 lg:tw-gap-x-4 xl:tw-gap-x-8">
        <div v-for="(card, index) in entityCard" :key="index">
          <div
            class="tw-grid tw-grid-cols-4 tw-bg-gray-lightest tw-rounded tw-p-6 tw-mb-8"
          >
            <div class="tw-flex tw-col-start-1 tw-col-end-4">
              <img
                v-if="card.type === 'VISA'"
                src="@/assets/img/visa.svg"
                alt="padlock"
              />
              <img
                v-if="card.type === 'MASTERCARD'"
                src="@/assets/img/mastercard.svg"
                alt="padlock"
              />
              <div class="tw-ml-4">
                <h3 class="tw-font-bold">****{{ card.last_four }}</h3>
                <p class="tw-text-black-light tw-text-sm">
                  Expires {{ card.expiry }}
                </p>
              </div>
            </div>
            <div
              class="dropdown tw-relative tw-justify-self-end tw-self-center tw-cursor-pointer"
            >
              <img src="@/assets/img/horizontal-dots.svg" alt="chevron" />
              <div
                class="menu tw-absolute tw-hidden tw-rounded tw-top-6 tw--left-16"
              >
                <ul class="tw-px-4">
                  <li
                    class="list tw-text-sm tw-bg-white tw-cursor-pointer tw-rounded tw-px-4 tw-py-1"
                    @click="deleteStoredCard(card)"
                  >
                    Delete
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <BankOptions
      v-if="show"
      @close-bank-options="show = false"
      :show="show"
      @bank-method-selected="processMethod"
    /> -->

    <AddBankModal
      v-if="show"
      title="Add Bank Account"
      @close="show = false"
      :showBackButton="false"
      :bgChange="true"
    >
      <template v-slot:content>
        <div class="tw-px-4 lg:tw-px-10">
          <!-- <h2 class="tw-font-bold tw-text-lg tw-text-secondary tw-mt-7">
            Select type of account
          </h2>
          <p class="tw-text-[#4C5B90] tw-mt-2">
            GetEquity lets you link a Nigerian bank account or an international
            bank account.
          </p> -->
          <SelectCountryForm v-if="show" @bank-connected="show = false" />
        </div>
      </template>
    </AddBankModal>
    <NordigenBanks
      v-if="nodigenModal"
      :show="nodigenModal"
      @close-modal="nodigenModal = false"
      @trigger-loader="toggleLoader"
    />
    <div v-if="linkToken">
      <PlaidLink
        clientName="GetEquity"
        :env="plaidEnv"
        :link_token="linkToken"
        :products="['auth', 'transactions']"
        :onSuccess="onPlaidSuccess"
        :onExit="onPlaidExit"
        :onEvent="onPlaidEvent"
      >
        <button ref="plaidLink" class="tw-hidden">Click to proceed</button>
      </PlaidLink>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapState, mapGetters } from "vuex";
  import MonoConnect from "@mono.co/connect.js";
  import bankApi from "@/utils/mono";
  import responseHandler from "@/utils/responseHandler.js";
  import NordigenBanks from "@/components/general/NordigenBanks";
  import PlaidLink from "vue-plaid-link2";
  import SelectCountryForm from "@/components/general/SelectCountryForm";
  import AddBankModal from "@/components/general/sidemodal/SideModal";
  export default {
    name: "BankInfo",
    data() {
      return {
        loading: true,
        show: false,
        nodigenModal: false,
        linkToken: "",
        plaidEnv: process.env.VUE_APP_PLAID_ENV,
        plaidSuccess: false,
        monoConnect: null,
      };
    },
    components: {
      NordigenBanks,
      PlaidLink,
      SelectCountryForm,
      AddBankModal,
    },
    computed: {
      ...mapGetters("Account", ["bankAccounts"]),
      ...mapState({
        entityCard: (state) => state.organisationModule.entityCard,
      }),
    },
    watch: {
      linkToken(val) {
        if (val.length > 0) {
          this.loading = true;
          setTimeout(() => {
            this.$refs.plaidLink.click();
          }, 500);
        }
      },
    },

    created() {
      this.getBankAccounts();
      this.getEntityCard();
    },

    async mounted() {
      if (this.$route.query.scope && this.$route.query.state) {
        // If we're sent from stitch
        if (!this.$route.query.code) {
          this.showToast({
            description: "We could not complete this integration.",
            display: true,
            type: "error",
          });
          this.fetchBankAccounts();
          this.$router.push({
            name: "Account",
            query: { currentTabComponent: "Bank Information" },
          });
        } else {
          const currentUrl = `${window.location.origin}/dashboard/account`;
          const data = {
            verifier: localStorage.getItem(this.$route.query.state),
            code: this.$route.query.code,
            redirectUri: currentUrl,
          };
          this.loading = true;
          try {
            await bankApi.finalizeBankConnection("stitch", data);
            this.$router.push({
              name: "Account",
              query: { currentTabComponent: "Bank Information" },
            });
            this.fetchBankAccounts();
            this.showToast({
              description: "Bank connection was successful",
              display: true,
              type: "success",
            });
            this.loading = false;
          } catch (error) {
            this.$router.push({
              name: "Account",
              query: { currentTabComponent: "Bank Information" },
            });
            this.loading = false;
            this.handleError(error);
          }
        }
      } else {
        this.fetchBankAccounts();
      }
      if (this.$route.query.ref) {
        // If we're sent from nordigen
        if (this.$route.query.error) {
          this.showToast({
            description: "We could not complete this integration.",
            display: true,
            type: "error",
          });
          this.$router.push({
            name: "Account",
            query: { currentTabComponent: "Bank Information" },
          });
        } else {
          const data = {
            code: this.$route.query.ref,
          };
          this.loading = true;
          try {
            await bankApi.finalizeBankConnection("nordigen", data);
            this.loading = false;
            this.showToast({
              description: "Bank connection was successful",
              display: true,
              type: "success",
            });
            this.$router.push({
              name: "Account",
              query: { currentTabComponent: "Bank Information" },
            });
          } catch (error) {
            this.loading = false;
            this.handleError(error);
            this.$router.push({
              name: "Account",
              query: { currentTabComponent: "Bank Information" },
            });
          }
        }
      }
    },
    methods: {
      ...mapActions("Account", ["fetchBankAccounts", "deleteBank"]),
      ...mapActions("organisationModule", ["getEntityCard", "deleteCard"]),
      ...mapActions(["showToast"]),
      async getBankAccounts() {
        try {
          await this.fetchBankAccounts();
          this.loading = false;
        } catch (error) {
          this.loading = false;
          return error;
        }
      },
      setFallbackImage(e) {
        e.target.src =
          "https://res.cloudinary.com/djalafcj9/image/upload/v1687342614/getequityV2/bank-fill_rxa7p7.svg";
      },
      async processMethod(method) {
        switch (method) {
          case "mono-ng":
            this.monoConnect = await this.setupMono("ng");
            this.monoConnect.open();
            break;
          case "mono-ke":
            this.monoConnect = await this.setupMono("ke");
            this.monoConnect.open();
            break;
          case "mono-gh":
            this.monoConnect = await this.setupMono("gh");
            this.monoConnect.open();
            break;
          case "Stitch":
            this.connectForeignAccount("stitch");
            break;
          case "Nordigen":
            this.nodigenModal = true;
            this.show = false;
            break;
          case "Plaid":
            this.connectForeignAccount("plaid");
            this.show = false;
            break;
          case "Manual":
            this.$router.push({ name: "AddBankManual" });
            break;
        }
      },

      setupMono(country) {
        // eslint-disable-next-line prefer-const
        let self = this;
        let key = null;
        switch (country) {
          case "ng":
            key = process.env.VUE_APP_MONO_BUSINESS;
            break;
          case "ke":
            key = process.env.VUE_APP_MONO_KE_PERSONAL;
            break;
          case "gh":
            key = process.env.VUE_APP_MONO_GH_BUSINESS;
            break;
        }
        const monoInstance = new MonoConnect({
          onSuccess: function(code) {
            self.loading = true;
            code.country = country;
            self.generalSuccessHandler("mono", code);
          },
          key,
        });
        monoInstance.setup();
        return monoInstance;
      },
      /**
       * @param {string} type
       * @param {object} data
       */
      async generalSuccessHandler(type, data) {
        try {
          const response = await bankApi.finalizeBankConnection(type, data);
          if (response.status === 201 || response.status === 200) {
            this.loading = false;
            this.showToast({
              description: "Bank connection was successful",
              display: true,
              type: "success",
            });
            this.getBankAccounts();
            this.show = false;
          } else {
            this.loading = false;
            responseHandler.handleError(response);
          }
        } catch (error) {
          this.loading = false;
          responseHandler.handleError(error);
        }
      },
      onPlaidLoad() {
        console.log("Plaid Loaded");
      },
      onPlaidSuccess(publicToken) {
        if (!this.plaidSuccess) {
          this.linkToken = "";
          this.generalSuccessHandler("plaid", { code: publicToken });
          this.plaidSuccess = true;
        }
      },
      onPlaidExit() {
        this.linkToken = "";
        this.loading = false;
      },
      onPlaidEvent(e) {
        if (e === "HANDOFF") {
          this.getBankAccounts();
        }
      },
      async removeBank(id) {
        this.loading = true;
        try {
          const response = await this.deleteBank(id);
          this.loading = false;
          const { message } = response.data;
          this.showToast({
            description: message,
            display: true,
            type: "success",
          });
          this.getBankAccounts();
        } catch (error) {
          this.loading = false;
          return error;
        }
      },
      toggleLoader(val) {
        this.loading = val;
      },
      async connectAccount() {
        try {
          this.Connect = await this.setupMono();
          this.Connect.open();
        } catch (error) {
          responseHandler.handleError(error);
        }
      },
      handlePlaidInitiationResponse(response) {
        this.plaidSuccess = false;
        this.linkToken = response.data.data.link;
      },
      handleStitchInitiationResponse(response) {
        const { link, state, verifier } = response.data.data;
        localStorage.setItem(state, verifier);
        window.location = link;
        this.loadingMessage = "Redirecting...";
      },
      deleteStoredCard(card) {
        this.deleteCard(card._id);
      },
      async connectForeignAccount(type) {
        this.loading = true;
        this.loadingMessage = "Please wait...";
        try {
          let response = null;
          switch (type) {
            case "stitch":
              const currentUrl = `${window.location.origin}/dashboard/account`;
              response = await bankApi.initiateBankConnection("stitch", {
                redirectUri: currentUrl,
              });
              break;
            case "plaid":
              response = await bankApi.initiateBankConnection(type);
          }
          if (response.status === 201 || response.status === 200) {
            this.loading = false;
            ((
              {
                stitch: () => this.handleStitchInitiationResponse(response),
                plaid: () => this.handlePlaidInitiationResponse(response),
              }[type] || (() => {})
            )());
          } else {
            this.loading = false;
            responseHandler.handleError(response.data);
          }
        } catch (error) {
          this.loading = false;
          responseHandler.handleError(error);
        }
      },
      loadError() {
        this.showToast({
          description: "Your Integration attempt failed.",
          display: true,
          type: "error",
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .bank-info {
    @include fadeIn;
    min-height: 66vh;
    overflow-y: scroll;
    &__list {
      gap: 16px;
    }
    &__item {
      background: #ffffff;
      box-sizing: border-box;
      border-radius: 8px;
      border: 1px solid #e1e6f0;
      width: 158px;
      height: 183px;
      &:hover {
        border: 1px solid #4d84ff;
        color: inherit;
      }
    }
    &__image {
      width: 30px;
      height: 30px;
      object-fit: contain;
    }
    &-country {
      &__modal {
        width: 350px;
      }
      &__dropdown {
        border: 1px solid #dde1e9;
      }
    }
    .dropdown {
      .menu {
        .list {
          transition: 0.3s ease all;

          &:hover {
            background-color: #375cb1;
            color: #ffffff;
          }
        }
      }

      &:hover .menu {
        display: block;
      }
    }
    @media (min-width: 768px) {
      min-height: 90vh;
      &-country {
        &__modal {
          width: 435px;
        }
      }
    }
  }
</style>
