<template>
  <SmallModal class="tw-z-20" @close="$emit('close-terminate-modal')">
    <template v-slot:content>
      <div class="header">
        <h3 class="tw-text-xl tw-font-CircularStd-medium tw-font-medium">
          Terminate Plan?
        </h3>
        <p class="tw-text-black-light tw-mt-6">
          This will cancel your current plan and all its features.
        </p>
      </div>
      <button
        class="tw-w-full tw-bg-error tw-text-white tw-rounded tw-py-3 tw-my-8"
        @click="terminatePlan"
      >
        <span v-if="!loading">Yes, Terminate</span>
        <img
          v-show="loading"
          class="tw-m-auto"
          src="@/assets/img/spinner.svg"
          alt="animated spinner"
        />
      </button>
      <p class="tw-text-center">
        <span
          class="tw-text-gray tw-cursor-pointer"
          @click="$emit('close-terminate-modal')"
        >
          No, never mind
        </span>
      </p>
    </template>
  </SmallModal>
</template>

<script setup>
  import SmallModal from "@/components/general/SmallModal";
  import { useStore } from "vuex";
  import { ref } from "vue";
  import { setItem } from "@/utils/storage";

  const store = useStore();
  const emit = defineEmits(["close-terminate-modal", "fetch-plan"]);
  const props = defineProps({
    planId: {
      type: String,
      required: true,
    },
  });

  const loading = ref(false);

  const terminatePlan = async () => {
    const id = props.planId;
    loading.value = true;
    try {
      const response = await store.dispatch(
        "organisationModule/terminatePlan",
        id
      );
      const { message } = response.data;
      store.dispatch("showToast", {
        description: message,
        display: true,
        type: "success",
      });
      loading.value = false;
      setItem("ge-subscription", null);
      emit("fetch-plan");
    } catch (error) {
      loading.value = false;
    }
  };
</script>
